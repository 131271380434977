import React, { Component } from 'react';
import { connect } from 'react-redux'
import Immutable from 'immutable'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { updateSelectedAddonIds } from '../../actions/upsert_booking_actions'
import { getAddons } from '../../reducers/addons_reducer'
import { getSelectedAddonIds } from '../../reducers/upsert_booking_reducer'
import BookingAddons from '../../components/bookings/BookingAddons'
import { addonsFromIds } from '../../utils/addons_helper'

class BookingAddonsContainer extends Component {
  render() {
    const { addons, bookingType, selectedAddonIds } = this.props
    const selectedAddons = addonsFromIds(addons, selectedAddonIds)
    return (
      <BookingAddons
        addons={ addons }
        bookingType={ bookingType }
        selectedAddons={ selectedAddons }
        toggleAddon={ this.toggleAddon }
      />
    )
  }

  toggleAddon = (addon, checked) => {
    const { selectedAddonIds, updateSelectedAddonIds } = this.props
    if(checked) {
      updateSelectedAddonIds(selectedAddonIds.push(addon.get('id')))
    } else {
      const index = selectedAddonIds.findIndex((id) => {
        return id === addon.get('id')
      })
      updateSelectedAddonIds(selectedAddonIds.delete(index))
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    addons: getAddons(state),
    selectedAddonIds: getSelectedAddonIds(state)
  }
}

const mapDispatchToProps = {
  updateSelectedAddonIds,
}

BookingAddonsContainer.propTypes = {
  bookingType: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAddonsContainer)
