import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Select from 'react-select-v2'
import '../../styles/components/modal/new_subscription_type_selection.scss'
import { navigate } from "@reach/router"
import { toggleModal } from '../../actions/modal_actions'

class SubscriptionSelectionModalContainer extends Component {
constructor(props){
    super()

    this.state = {
      selectedType: "weekly"
    }
  }

  getOptions = () => {
    return [
      {label: 'Weekly', value: 'weekly'},
      {label: 'Bi-Weekly', value: 'bi_weekly'},
      {label: 'Monthly', value: 'monthly'},
    ]
  }

  render() {
    const { selectedType } = this.state
    const options = this.getOptions()
    const val = options.filter((option) => option.value === selectedType)[0]
    return (
      <div className='subscription-type-selection'>
        <h4>How often would you like your house to be cleaned?</h4>
        <Select
          className='subscription-type-dropdown'
          options={ options }
          value={ val }
          onChange={ this.onChange }
        />
        <div className='btn btn-primary' onClick={ this.routeToNewBooking }>
          Schedule First Recurring Booking
        </div>
      </div>
    );
  }

  routeToNewBooking = () => {
    const { selectedType } = this.state
    const { routePrefix, toggleModal } = this.props
    navigate(`${routePrefix}?type=${selectedType}`)
    toggleModal()
  }

  onChange = (option) => {
    this.setState({ selectedType: option.value })
  }
}

const mapStateToProps = (state, props) => {
}

const mapDispatchToProps = {
  toggleModal
}

SubscriptionSelectionModalContainer.propTypes = {
  routePrefix: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSelectionModalContainer)
