import Immutable from 'immutable'
import { createReducer, shouldFetchValue } from '../utils/reducers'
import {
  BOOKING_FETCH_RECIEVED,
  CREDIT_CARD_SUBMISSION_RECIEVED,
  LOGIN_RECIEVED,
  LOGOUT_USER,
  USER_CREATE_REQUESTED,
  USER_CREATE_RECIEVED,
  USER_CREATE_FAILED,
  USER_FETCH_REQUESTED,
  USER_FETCH_RECIEVED,
  USER_FETCH_FAILED,
  USER_DASHBOARD_FETCH_RECIEVED,
  USER_UPDATE_RECIEVED
} from '../utils/constants'

export const stateKey = 'users'
export const localStorageKey = 'wellnest-current-user'

let initialState = () => {
  const localStorageUser = window.localStorage.getItem(localStorageKey)
  const formattedUser = Immutable.Map(JSON.parse(localStorageUser))
  const state = {
    fetching: false,
    currentUser: localStorageUser? formattedUser : undefined,
    users: {}
  }
  if(localStorageUser) {
    state.users[formattedUser.get('id')] = formattedUser
  }
  return Immutable.fromJS(state)
}

const UserReducer = (state = initialState(), action) => {
  return createReducer(state, action, {
    [LOGIN_RECIEVED]: (state, payload) => {
      const user = Immutable.Map(payload.results)
      setCurrentUserToLocalStorage(payload.results)
      return state.set('currentUser', user).setIn(['users', user.get('id')], user)
    },
    [LOGOUT_USER]: (state) => {
      return state.set('currentUser', undefined)
    },
    [USER_CREATE_REQUESTED]: (state) => {
      return state.set('fetching', true)
    },
    [USER_CREATE_RECIEVED]: (state, payload) => {
      const user = Immutable.Map(payload.results)
      setCurrentUserToLocalStorage(payload.results)
      return state.set('fetching', false).set('currentUser', user).setIn(['users', user.get('id')], user)
    },
    [USER_CREATE_FAILED]: (state) => {
      return state.set('fetching', false)
    },
    [USER_FETCH_REQUESTED]: (state) => {
      return state.set('fetching', true)
    },
    [USER_FETCH_RECIEVED]: (state, payload) => {
      return state.set('fetching', false)
        .setIn(['users', payload.payload.id], Immutable.fromJS(payload.payload))
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload }) => {
      return state.setIn(['users', payload.user.id], Immutable.fromJS(payload.user))
    },
    [BOOKING_FETCH_RECIEVED]: (state, { payload }) => {
      const user = payload.user
      return state.setIn(['users', user.id], Immutable.fromJS(payload.user))
    },
    [USER_UPDATE_RECIEVED]: (state, { results, userId }) => {
      return state.setIn(['users', userId], Immutable.fromJS(results))
    },
  });
}

export const getCurrentUser = (globalState) => getUserState(globalState).get('currentUser')
export const getUser = (globalState, userId) => {
  return getUserState(globalState).getIn(['users', parseInt(userId)]) ||
          getUserState(globalState).getIn(['users', userId.toString()])
}

const setCurrentUserToLocalStorage = (user) => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(user))
}

export const getUserToken = (globalState) => getUserState(globalState).getIn(['currentUser', 'auth_token'])

export const shouldFetchUser = (globalState, userId) => {
  return shouldFetchValue(getUserState(globalState), ['users', userId], 3)
}

export const isFetching = (globalState) => {
  return getUserState(globalState).get('fetching')
}

export const getUserState = (globalState) => globalState[stateKey];
export default UserReducer
