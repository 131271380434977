import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  RECOVERY_CODE_CREATE_REQUESTED,
  RECOVERY_CODE_CREATE_RECIEVED,
  RECOVERY_CODE_CREATE_FAILED,
} from '../utils/constants'

export const stateKey = 'password_reset'

let initialState = Immutable.Map({
  isFetching: false
})

const PasswordResetReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [RECOVERY_CODE_CREATE_REQUESTED]: (state) => {
      return state.set('isFetching', true)
    },
    [RECOVERY_CODE_CREATE_RECIEVED]: (state) => {
      return state.set('isFetching', false)
    },
    [RECOVERY_CODE_CREATE_FAILED]: (state) => {
      return state.set('isFetching', false)
    }
  });
}

export const isFetching = (globalState) => {
  return getState(globalState).get('isFetching')
}

export const getState = (globalState) => globalState[stateKey];
export default PasswordResetReducer
