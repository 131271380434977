import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import { apiDateFormat, parseDate } from '../utils/date_helper'
import moment from 'moment'
import {
  UPDATE_ADMIN_BOOKINGS_WINDOW,
  UPDATE_ADMIN_REGION_FILTER,
} from '../utils/constants'
import { getPreviousMonday } from '../utils/date_helper'

export const stateKey = 'admin_dashboard'

const initialWindowStart = getPreviousMonday()
let initialState = Immutable.Map({
  bookingsWindowStart: apiDateFormat(initialWindowStart),
  bookingsWindowEnd: apiDateFormat(moment(initialWindowStart).add(6, 'days').toDate()),
  regionFilter: undefined
})

const AdminDashboardReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [UPDATE_ADMIN_BOOKINGS_WINDOW]: (state, { windowStart, windowEnd }) => {
      return state.set('bookingsWindowStart', apiDateFormat(windowStart))
      .set('bookingsWindowEnd', apiDateFormat(windowEnd))
    },
    [UPDATE_ADMIN_REGION_FILTER]: (state, { regionTitle }) => {
      return state.set('regionFilter', regionTitle)
    }
  });
}

export const getBookingsWindowStart = (globalState) => parseDate(getState(globalState).get('bookingsWindowStart'))
export const getBookingsWindowEnd = (globalState) => parseDate(getState(globalState).get('bookingsWindowEnd'))
export const getRegionFilter = (globalState) => getState(globalState).get('regionFilter')

export const getState = (globalState) => globalState[stateKey];
export default AdminDashboardReducer
