import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { getUserSubscriptions } from '../../reducers/subscription_reducer'
import { getCurrentUser } from '../../reducers/user_reducer'
import { toggleModal } from '../../actions/modal_actions'
import { userFriendlyName, frequencyText } from '../../utils/subscription_helper'
import { formatTime } from '../../utils/date_helper'
import '../../styles/containers/user_dashboard/subscription_container.scss'
import { Alert, Button, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2'

class SubscriptionContainer extends Component {
  state = {
    error: false
  }

  renderSubscriptions = () => {
    const { subscriptions, toggleModal, user } = this.props
    return subscriptions.map((subscription) => {
      return (
        <div className='user-dashboard-subscription'>
          <p>
            On <b>{ subscription.get('startText') }</b> at <b>{ formatTime(subscription.get('time')) }</b> every <b>{ frequencyText(subscription.get('frequency')) }</b>
          </p>
          <p className='subtitle'>
            Billed with card ending in {subscription.getIn(['creditCard', 'lastFour'])}&nbsp;&nbsp;
            <a onClick={ () => toggleModal('SubscriptionCreditCardSelectionModalContainer', { userId: user.get('id'), subscription }) }>
             Click Here To Update
             </a>
          </p>
          <div className='btn btn-primary' onClick={ () => this.handleCancelSubscription(subscription) }>
            Cancel Subscription
          </div>
        </div>
      )
    })
  }

  renderAddNewSubscription = () => {
    const { currentUser } = this.props
    if(currentUser.get('admin')){
      return <div className='btn btn-primary' onClick={ this.openModal }>
        Add Addtional Subscription
      </div>
    } else {
      return null
    }
  }

  render() {
    const { subscriptions } = this.props
    const { error } = this.state
    const errorMessage = error ? this.errorMessage() : ''

    if(subscriptions && subscriptions.size > 0) {
      return (
        <div className='user-dashboard-subscriptions'>
          { this.renderSubscriptions() }
          { this.renderAddNewSubscription() }
        </div>
      )
    } else {
      return (
        <div className='user-dashboard-subscriptions'>
          <span>It looks like you don't have any recurring bookings</span>
          <br/>
          <div className='btn btn-primary' onClick={ this.openModal }>
            Get Regular Cleanings
          </div>
        </div>
      )
    }
  }

  handleCancelSubscription = (subscription) => {
    const { user, toggleModal } = this.props
    toggleModal('SubscriptionCancellationModal', {
      userId: user.get('id'),
      subscriptionId: subscription.get('id')
    })
  }

  onDeleteError = () => {
    this.setState({ error: true })
  }

  errorMessage = () => {
    return (
      <Alert color="danger">Uh Oh. Something went wrong trying to delete your subscription. Please try again.</Alert>
    )
  }

  openModal = () => {
    const { currentUser,toggleModal, user } = this.props
    let routePrefix
    if(currentUser.get('id') === user.get('id')) {
      routePrefix = '/bookings'
    } else {
      routePrefix = `/users/${user.get('id')}/bookings`
    }
    toggleModal('SubscriptionSelectionModalContainer', { routePrefix })
  }
}

const mapStateToProps = (state, props) => {
  return {
    subscriptions: getUserSubscriptions(state, props.user.get('id')),
    currentUser: getCurrentUser(state)
  }
}

const mapDispatchToProps = {
  toggleModal
}

SubscriptionContainer.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer)
