import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {
  required,
  isNumber,
  lengthInRange,
  isLength
} from '../../utils/validations'
import BasicForm from '../../components/forms/BasicForm'
import { toggleModal } from '../../actions/modal_actions'
import { submitUserCreditCard } from '../../actions/credit_card_actions'
import { isFetching } from '../../reducers/credit_cards_reducer'
import { Alert } from 'reactstrap'

class NewCreditCardModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      submissionError: undefined
    }
  }

  fields = [
    {
      label: 'Number',
      name: 'number',
      placeholder: '**** **** **** ****',
      type: 'text',
      validations: [
        required,
        isNumber,
        (value) => lengthInRange(13, 16, value, 'Invalid credit card number')
      ]
    },
    {
      label: 'Expiration Month',
      name: 'expMonth',
      placeholder: '06',
      type: 'text',
      validations: [
        required,
        isNumber,
        (value) => isLength(2, value, 'Invalid expiration month. Please enter two digits')
      ]
    },
    {
      label: 'Expiration Year',
      name: 'expYear',
      placeholder: '21',
      type: 'text',
      validations: [
        required,
        isNumber,
        (value) => isLength(2, value, 'Invalid expiration year. Please enter two digits')
      ]
    },
    {
      label: 'CVC',
      name: 'cvc',
      placeholder: '***',
      type: 'text',
      validations: [
        required,
        isNumber,
        (value) => lengthInRange(3, 4, value, 'Invalid CVC')
      ]
    },
    {
      label: 'Zip Code',
      name: 'zipCode',
      placeholder: '22040',
      type: 'text',
      validations: [
        required,
        isNumber,
        (value) => isLength(5, value, 'Invalid zip code')
      ]
    },
  ]

  render() {
    const { addressIsSaving } = this.props
    const { submissionError } = this.state
    const alert = submissionError ? <Alert color="danger">{ submissionError }</Alert> : ''
    return (
      <div className='new-credit-card-modal-container'>
        <h4>Add New Credit Card</h4>
        <BasicForm
          fields={ this.fields }
          onSubmit={ this.onSubmit }
          isSaving={ addressIsSaving }
        />
        { alert }
      </div>
    )
  }

  onSubmit = (creditCard) => {
    const { submitUserCreditCard, onCreationSuccess, toggleModal, userId } = this.props
    this.setState({ submissionError: undefined })
    submitUserCreditCard(
      creditCard,
      userId,
      (cc) => {
        onCreationSuccess(cc)
        toggleModal()
      },
      (error) => {
        this.setState({submissionError: error.message})
      }
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    addressIsSaving: isFetching(state)
  }
}

const mapDispatchToProps = {
  submitUserCreditCard,
  toggleModal
}

NewCreditCardModalContainer.propTypes = {
  submitUserCreditCard: PropTypes.func.isRequired,
  onCreationSuccess: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditCardModalContainer)
