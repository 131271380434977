import {
  CREDIT_CARDS_FETCH_REQUESTED,
  CREDIT_CARDS_FETCH_RECIEVED,
  CREDIT_CARDS_FETCH_FAILED,
  CREDIT_CARD_SUBMISSION_REQUESTED,
  CREDIT_CARD_SUBMISSION_RECIEVED,
  CREDIT_CARD_SUBMISSION_FAILED,
  CREDIT_CARD_DELETE_REQUESTED,
  CREDIT_CARD_DELETE_RECIEVED,
  CREDIT_CARD_DELETE_FAILED
} from '../utils/constants'

import { fetchAction } from '../utils/actions'
import { shouldFetchCreditCards } from '../reducers/credit_cards_reducer'

export function fetchUserCreditCards(userId) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchCreditCards(state, userId)
    },
    requestParams: {
      method: 'GET',
      url: `/users/${userId}/credit_cards`,
    },
    requestAction: () => { return { type: CREDIT_CARDS_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: CREDIT_CARDS_FETCH_RECIEVED, payload: { results, userId } } },
    failureAction: (error) => { return { type: CREDIT_CARDS_FETCH_FAILED, payload: { error } } }
  });
}

export function submitUserCreditCard(creditCard, userId, onSuccess, onFailure) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/users/${userId}/credit_cards`,
      data: { creditCard }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: CREDIT_CARD_SUBMISSION_REQUESTED } },
    receivedAction: (results) => { return { type: CREDIT_CARD_SUBMISSION_RECIEVED, creditCard: results, userId } },
    failureAction: (error) => { return { type: CREDIT_CARD_SUBMISSION_FAILED, payload: { error } } }
  });
}

export function deleteCreditCard(creditCardId, userId, onSuccess, onFailure) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'DELETE',
      url: `/users/${userId}/credit_cards/${creditCardId}`
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: CREDIT_CARD_DELETE_REQUESTED } },
    receivedAction: (results) => { return { type: CREDIT_CARD_DELETE_RECIEVED, creditCardId, userId } },
    failureAction: (error) => { return { type: CREDIT_CARD_DELETE_FAILED, payload: { error } } }
  });
}
