import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Badge } from 'reactstrap'
import { shortDate, formatTime } from '../../utils/date_helper'

import calendar from '../../assets/icons/calendar.svg'
import check from '../../assets/icons/checkmark_circle.svg'
import pencil from '../../assets/icons/pencil.svg'
import trash from '../../assets/icons/trash.svg'

export default class AdminDashboardDailyBookings extends Component {

  constructor(props) {
    super(props)
    this.state = { isDayOpen: null }
  }

  render() {
    return (
      <React.Fragment>
          { this.renderToggleHeader() }
          { this.renderBookingTable() }
      </React.Fragment>
    )
  }

  toggleCollapse = () => {
    this.setState({ isDayOpen: !this.state.isDayOpen })
  }

  renderToggleHeader = () => {
    const { date, dailyBookings } = this.props
    const { isDayOpen } = this.state
    const className = dailyBookings.length ? 'enabled' : 'disabled'
    const description = dailyBookings.length ? `${dailyBookings.length} Bookings` : 'No Bookings'
    return (
      <div className={`admin-daily-bookings-header ${className}`} onClick={ this.toggleCollapse }>
        <div className='admin-daily-bookings-header-title'>{ shortDate(date) }</div>
        <div className='admin-daily-bookings-header-description'>{ description }</div>
      </div>
    )
  }

  renderBookingTable = () => {
    const { openAllDays, date, dailyBookings } = this.props
    const { isDayOpen } = this.state
    if(dailyBookings.length) {
      return (
        <Collapse isOpen={isDayOpen || openAllDays}>
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Customer</th>
                  <th scope="col">Time</th>
                  <th scope="col">Type</th>
                  <th scope="col">Address</th>
                  <th scope="col">Region</th>
                  <th scope="col">Addons</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderTableRows() }
              </tbody>
            </table>
          </div>
        </Collapse>
      )
    } else {
      return null
    }
  }

  renderTableRows = () => {
    const { dailyBookings, redirectToUserDashboard } = this.props

    return dailyBookings.map((booking) => {
      let completeBadge;
      if(booking.status === 'complete'){
        completeBadge = (
          <React.Fragment>
            <br/>
            <Badge>Complete</Badge>
          </React.Fragment>
        )
      }
      return (
        <tr>
          <td>
            <a onClick={ () => {redirectToUserDashboard(booking)} }>{booking.user.first_name} {booking.user.last_name}</a>
            <br/>
            {booking.user.email}
            <br/>
            {booking.user.phone}
            {completeBadge}
          </td>
          <td>
            { shortDate(booking.start_date) }
            <br/>
            {formatTime(booking.start_time)}
          </td>
          <td>{booking.type}</td>
          <td>
            {booking.address.street}
            <br/>
            {booking.address.bedrooms}bd/{booking.address.bathrooms}ba
            <br/>
            { booking.address.special_instructions }
          </td>
          <td>{booking.region}</td>
          <td>{booking.addons}</td>
          <td>
            { booking.team_note &&
              <React.Fragment>
                <b>One Time Note</b>
                <br/>
                {booking.team_note}
                <br/>
              </React.Fragment>}
            { booking.user.recurring_note &&
              <React.Fragment>
                <b>Recurring Note</b>
                <br/>
                {booking.user.recurring_note}
                <br/>
              </React.Fragment>}
            { booking.user.internal_note &&
              <React.Fragment>
                <b>Internal Note</b>
                <br/>
                {booking.user.internal_note}
              </React.Fragment>}
          </td>
          { this.renderActions(booking) }
        </tr>
      )
    })
  }

  renderActions = (booking) => {
    const {
      completeBooking,
      deleteBooking,
      editBooking,
      rescheduleBooking
    } = this.props

    let completeAction;
    if(booking.status !== 'complete') {
      completeAction = (
        <img
          title='Complete Booking'
          className='complete-action'
          src={check}
          onClick={ () => { completeBooking(booking)} }
        />
      )
    }

    return (
      <td className='admin-booking-actions'>
        <div className='admin-booking-actions-row'>
          <img
            title='Edit Booking'
            className='edit-action'
            src={pencil}
            onClick={ () => { editBooking(booking)} }
          />
        </div>
        <div className='admin-booking-actions-row'>
          <img
            title='Delete Booking'
            className='delete-action'
            src={trash}
            onClick={ () => { deleteBooking(booking)} }
          />
        { completeAction }
        </div>
      </td>
    )
  }
}

AdminDashboardDailyBookings.propTypes = {
  completeBooking: PropTypes.func.isRequired,
  dailyBookings: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  editBooking: PropTypes.func.isRequired,
  openAllDays: PropTypes.bool,
  redirectToUserDashboard: PropTypes.func.isRequired,
  rescheduleBooking: PropTypes.func.isRequired
};
