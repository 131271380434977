import {
  USER_DASHBOARD_FETCH_REQUESTED,
  USER_DASHBOARD_FETCH_RECIEVED,
  USER_DASHBOARD_FETCH_FAILED
} from '../utils/constants'

import { fetchAction } from '../utils/actions'
import { shouldFetchBookings } from '../reducers/bookings_reducer'

export function fetchUserDashboard(userId, onSuccess) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchBookings(state, userId)
    },
    requestParams: {
      method: 'GET',
      url: `/users/${userId}/dashboard`,
    },
    onSuccess,
    requestAction: () => { return { type: USER_DASHBOARD_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: USER_DASHBOARD_FETCH_RECIEVED, payload: results, userId } },
    failureAction: (error) => { return { type: USER_DASHBOARD_FETCH_FAILED, payload: { error } } }
  });
}
