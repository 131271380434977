import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { toggleModal } from '../../actions/modal_actions'
import { deleteSubscription } from '../../actions/subscription_actions'
import BasicForm from '../../components/forms/BasicForm'
import { required } from '../../utils/validations'
import { Alert } from 'reactstrap'


class SubscriptionCancellationModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: false,
      submitting: false,
    }
  }

  render() {
    const { error, submitting } = this.state
    let errorMessage = error ? <Alert color="danger">Uh Oh! Something Went Wrong. Please Try Again.</Alert> : null
    return (
      <div >
        <h2>Are you sure?</h2>
        Deleting a subscription will also cancel all future, editable appointments.
        <br/><br/>
        <b>Why are you cancelling?</b>
        <br/>
        <BasicForm
          fields={ this.fields() }
          onSubmit={ this.onSubmit }
          isSaving={submitting}
          submitText='Cancel Subscription'
          submittingText='Cancelling Subscription'
        />
        { errorMessage }
      </div>
    )
  }

  fields = () => {
    return [{
      name: 'reason',
      placeholder: 'Let us know about any issues we can help fix',
      type: 'textarea',
      validations: [required]
    }]
  }

  onSubmit = (formValues) => {
    const { deleteSubscription, subscriptionId, userId, toggleModal } = this.props
    this.setState({ submitting: true })
    deleteSubscription({
      subscriptionId,
      userId,
      reason: formValues.reason,
      onSuccess: toggleModal,
      onFailure: () => { this.setState({ submitting: false, error: true }) }
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = {
  deleteSubscription,
  toggleModal
}

SubscriptionCancellationModal.propTypes = {
  userId: PropTypes.number,
  subscriptionId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCancellationModal)
