export const ADDRESSES_FETCH_REQUESTED = 'ADDRESSES_FETCH_REQUESTED'
export const ADDRESSES_FETCH_RECIEVED = 'ADDRESSES_FETCH_RECIEVED'
export const ADDRESSES_FETCH_FAILED = 'ADDRESSES_FETCH_FAILED'

export const DELETE_ADDRESS_REQUESTED = 'DELETE_ADDRESS_REQUESTED'
export const DELETE_ADDRESS_RECIEVED = 'DELETE_ADDRESS_RECIEVED'
export const DELETE_ADDRESS_FAILED = 'DELETE_ADDRESS_FAILED'

export const ADDRESSES_SUBMISSION_REQUESTED = 'ADDRESSES_SUBMISSION_REQUESTED'
export const ADDRESSES_SUBMISSION_RECIEVED = 'ADDRESSES_SUBMISSION_RECIEVED'
export const ADDRESSES_SUBMISSION_FAILED = 'ADDRESSES_SUBMISSION_FAILED'

export const BOOKING_CALENDAR_FETCH_REQUESTED = 'BOOKING_CALENDAR_FETCH_REQUESTED'
export const BOOKING_CALENDAR_FETCH_RECIEVED = 'BOOKING_CALENDAR_FETCH_RECIEVED'
export const BOOKING_CALENDAR_FETCH_FAILED = 'BOOKING_CALENDAR_FETCH_FAILED'
export const SET_BOOKING_CALENDAR_OVERRIDE_MODE = 'SET_BOOKING_CALENDAR_OVERRIDE_MODE'

export const ONBOARDING_CALENDAR_FETCH_REQUESTED = 'ONBOARDING_CALENDAR_FETCH_REQUESTED'
export const ONBOARDING_CALENDAR_FETCH_RECIEVED = 'ONBOARDING_CALENDAR_FETCH_RECIEVED'
export const ONBOARDING_CALENDAR_FETCH_FAILED = 'ONBOARDING_CALENDAR_FETCH_FAILED'

export const CREDIT_CARDS_FETCH_REQUESTED = 'CREDIT_CARDS_FETCH_REQUESTED'
export const CREDIT_CARDS_FETCH_RECIEVED = 'CREDIT_CARDS_FETCH_RECIEVED'
export const CREDIT_CARDS_FETCH_FAILED = 'CREDIT_CARDS_FETCH_FAILED'

export const CREDIT_CARD_SUBMISSION_REQUESTED = 'CREDIT_CARD_SUBMISSION_REQUESTED'
export const CREDIT_CARD_SUBMISSION_RECIEVED = 'CREDIT_CARD_SUBMISSION_RECIEVED'
export const CREDIT_CARD_SUBMISSION_FAILED = 'CREDIT_CARD_SUBMISSION_FAILED'

export const CREDIT_CARD_DELETE_REQUESTED = 'CREDIT_CARD_DELETE_REQUESTED'
export const CREDIT_CARD_DELETE_RECIEVED = 'CREDIT_CARD_DELETE_RECIEVED'
export const CREDIT_CARD_DELETE_FAILED = 'CREDIT_CARD_DELETE_FAILED'

export const SELECTED_BOOKING_ADDRESS_UPDATE = 'SELECTED_BOOKING_ADDRESS_UPDATE'

export const BOOKING_SUBMISSION_REQUESTED = 'BOOKING_SUBMISSION_REQUESTED'
export const BOOKING_SUBMISSION_RECIEVED = 'BOOKING_SUBMISSION_RECIEVED'
export const BOOKING_SUBMISSION_FAILED = 'BOOKING_SUBMISSION_FAILED'
export const BOOKING_UPDATE_REQUESTED= 'BOOKING_UPDATE_REQUESTED'
export const BOOKING_UPDATE_RECIEVED= 'BOOKING_UPDATE_RECIEVED'
export const BOOKING_UPDATE_FAILED= 'BOOKING_UPDATE_FAILED'
export const BOOKING_DELETE_REQUESTED = 'BOOKING_DELETE_REQUESTED'
export const BOOKING_DELETE_RECIEVED = 'BOOKING_DELETE_RECIEVED'
export const BOOKING_DELETE_FAILED = 'BOOKING_DELETE_FAILED'
export const BOOKING_FETCH_REQUESTED = 'BOOKING_FETCH_REQUESTED'
export const BOOKING_FETCH_RECIEVED = 'BOOKING_FETCH_RECIEVED'
export const BOOKING_FETCH_FAILED = 'BOOKING_FETCH_FAILED'
export const BOOKING_COMPLETION_REQUESTED = 'BOOKING_COMPLETION_REQUESTED'
export const BOOKING_COMPLETION_RECIEVED = 'BOOKING_COMPLETION_RECIEVED'
export const BOOKING_COMPLETION_FAILED = 'BOOKING_FETCH_FAILED'
export const BOOKING_TEST_RECEIPT_EMAIL_REQUESTED = 'BOOKING_TEST_RECEIPT_EMAIL_REQUESTED'
export const BOOKING_TEST_RECEIPT_EMAIL_RECEIVED = 'BOOKING_TEST_RECEIPT_EMAIL_RECEIVED'
export const BOOKING_TEST_RECEIPT_EMAIL_FAILED = 'BOOKING_TEST_RECEIPT_EMAIL_FAILED'


export const CLEAR_BOOKING_DATE_AND_TIME = 'CLEAR_BOOKING_DATE_AND_TIME'
export const UPDATE_SELECTED_ADDON_IDS = 'UPDATE_SELECTED_ADDON_IDS'
export const UPDATE_SELECTED_ADDRESS_ID = 'UPDATE_SELECTED_ADDRESS_ID'
export const UPDATE_SELECTED_CREDIT_CARD_ID = 'UPDATE_SELECTED_CREDIT_CARD_ID'
export const UPDATE_SELECTED_DATE = 'UPDATE_SELECTED_DATE'
export const UPDATE_SELECTED_TIME = 'UPDATE_SELECTED_TIME'

export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED'
export const USER_FETCH_RECIEVED = 'USER_FETCH_RECIEVED'
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED'
export const USER_CREATE_REQUESTED = 'USER_CREATE_REQUESTED'
export const USER_CREATE_RECIEVED = 'USER_CREATE_RECIEVED'
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED'

export const USER_DASHBOARD_FETCH_REQUESTED = 'USER_DASHBOARD_FETCH_REQUESTED'
export const USER_DASHBOARD_FETCH_RECIEVED = 'USER_DASHBOARD_FETCH_RECIEVED'
export const USER_DASHBOARD_FETCH_FAILED = 'USER_DASHBOARD_FETCH_FAILED'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_RECIEVED = 'LOGIN_RECIEVED'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_USER = 'LOGOUT_USER'

export const RECOVERY_CODE_CREATE_REQUESTED = 'RECOVERY_CODE_CREATE_REQUESTED'
export const RECOVERY_CODE_CREATE_RECIEVED = 'RECOVERY_CODE_CREATE_RECIEVED'
export const RECOVERY_CODE_CREATE_FAILED = 'RECOVERY_CODE_CREATE_FAILED'

export const UPDATE_ADMIN_BOOKINGS_WINDOW = 'UPDATE_ADMIN_BOOKINGS_WINDOW'
export const UPDATE_ADMIN_REGION_FILTER = 'UPDATE_ADMIN_REGION_FILTER'
export const FETCH_ADMIN_BOOKINGS_REQUESTED = 'FETCH_ADMIN_BOOKINGS_REQUESTED'
export const FETCH_ADMIN_BOOKINGS_RECIEVED = 'FETCH_ADMIN_BOOKINGS_RECIEVED'
export const FETCH_ADMIN_BOOKINGS_FAILED = 'FETCH_ADMIN_BOOKINGS_FAILED'
export const USER_SEARCH_REQUESTED = 'USER_SEARCH_REQUESTED'
export const USER_SEARCH_RECIEVED = 'USER_SEARCH_RECIEVED'
export const USER_SEARCH_FAILED = 'USER_SEARCH_FAILED'

export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED'
export const USER_UPDATE_RECIEVED = 'USER_UPDATE_RECIEVED'
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED'

export const SUBSCRIPTION_DELETE_REQUESTED = 'SUBSCRIPTION_DELETE_REQUESTED'
export const SUBSCRIPTION_DELETE_RECIEVED = 'SUBSCRIPTION_DELETE_RECIEVED'
export const SUBSCRIPTION_DELETE_FAILED = 'SUBSCRIPTION_DELETE_FAILED'

export const SUBSCRIPTION_UPDATE_REQUESTED = 'SUBSCRIPTION_UPDATE_REQUESTED'
export const SUBSCRIPTION_UPDATE_RECIEVED = 'SUBSCRIPTION_UPDATE_RECIEVED'
export const SUBSCRIPTION_UPDATE_FAILED = 'SUBSCRIPTION_UPDATE_FAILED'

export const FEEDBACK_SUBMISSION_REQUESTED = 'FEEDBACK_SUBMISSION_REQUESTED'
export const FEEDBACK_SUBMISSION_RECIEVED = 'FEEDBACK_SUBMISSION_RECIEVED'
export const FEEDBACK_SUBMISSION_FAILED = 'FEEDBACK_SUBMISSION_FAILED'
