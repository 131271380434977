import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import BasicForm from '../../components/forms/BasicForm'
import { Alert } from 'reactstrap'
import { required, email, phone, zipCode, isNumber } from '../../utils/validations'
import { createUser } from '../../actions/user_actions'
import { isFetching } from '../../reducers/user_reducer'
import { navigate } from '@reach/router'
import { buildRedirectUrl } from '../../utils/route_helper'

class SignUpContainer extends Component {
  state = {
    error: undefined
  }

  render() {
    const { isCreatingUser } = this.props
    return (
      <div className='auth-container'>
        <BasicForm
          fields={ this.fields() }
          onSubmit={ this.onSubmit }
          submitDisabled={ false }
          isSaving={ isCreatingUser }
        />
        { this.renderError() }
      </div>

    )
  }

  renderError = () => {
    const { error } = this.state
    if(error) {
      return (
        <Alert color="danger">
          { error }
        </Alert>
      )
    } else {
      return null
    }
  }

  fields(){
    const { routeProps } = this.props
    return [
      {
        label: 'Email',
        name: 'email',
        placeholder: 'test@test.com',
        type: 'text',
        validations: [required, email],
        customClass: 'col-6',
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: '*******',
        type: 'password',
        validations: [required],
        customClass: 'col-6',
      },
      {
        label: 'First Name',
        name: 'firstName',
        placeholder: 'John',
        type: 'text',
        validations: [required],
        customClass: 'col-6',
      },
      {
        label: 'Last Name',
        name: 'lastName',
        placeholder: 'Doe',
        type: 'text',
        validations: [required],
        customClass: 'col-6',
      },
      {
        label: 'Phone Number',
        name: 'phone',
        placeholder: '123-123-1234',
        type: 'text',
        validations: [required, phone],
        customClass: 'col-6',
      },
      {
        label: 'Referral Code',
        name: 'referral',
        placeholder: 'ABC123',
        type: 'text',
        validations: [],
        customClass: 'col-6',
      },
      {
        label: 'Address Street',
        name: 'street',
        placeholder: '1600 Pennsylvania Ave.',
        type: 'text',
        validations: [required],
        customClass: 'col-9',
      },
      {
        label: 'Unit',
        name: 'unit',
        placeholder: '1A',
        type: 'text',
        validations: [],
        customClass: 'col-3',
      },
      {
        label: 'Zip Code',
        name: 'zipCode',
        placeholder: '22040',
        type: 'text',
        validations: [required, zipCode], //number, zipcode
        value: routeProps.zipcode,
        disabled: !!routeProps.zipcode
      },
      {
        label: 'Bedrooms',
        name: 'bedrooms',
        placeholder: 'As Per MLS',
        type: 'text',
        validations: [required, isNumber], //number
        customClass: 'col-6',
        value: routeProps.bedrooms,
        disabled: !!routeProps.bedrooms
      },
      {
        label: 'Bathrooms',
        name: 'bathrooms',
        placeholder: 'As Per MLS',
        type: 'text',
        validations: [required, isNumber], //number
        customClass: 'col-6',
        value: routeProps.bathrooms,
        disabled: !!routeProps.bathrooms
      },
      {
        label: 'How Should We Enter?',
        name: 'entryInformation',
        placeholder: 'I will let you in, the doorman will give you access, or the key will be under the mat',
        type: 'textarea',
        validations: []
      },
      {
        label: 'Any Special Instructions?',
        name: 'specialInstructions',
        placeholder: 'Lockbox codes, trouble areas, areas to not clean, areas to focus on, anything specific we should know before cleaning your home!',
        type: 'textarea',
        validations: []
      },
    ]
  }

  onSubmit = (formResults) => {
    const { createUser, routeProps} = this.props
    this.setState({ error: undefined })
    createUser({
      ...formResults,
      onSuccess: (user) => {
        if(formResults.referral && formResults.referral.length > 0){
          routeProps.discount_code = formResults.referral
        }
        if(user.admin) {
          navigate(buildRedirectUrl(routeProps) || `admin/dashboard`)
        } else {
          const redirect = buildRedirectUrl(routeProps);
          navigate(redirect || `/users/${user.id}`)
        }
      },
      onFailure: (error) => {
        let errorMessage
        if(error.status) {
          errorMessage = 'Uh oh! Something went wrong trying to create your account. Please try again'
        } else {
          errorMessage = error.message
        }
        this.setState({ error: errorMessage })
      }
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    isCreatingUser: isFetching(state)
  }
}

const mapDispatchToProps = {
  createUser
}

SignUpContainer.propTypes = {
  routeProps: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer)
