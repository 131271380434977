import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_RECIEVED,
  USER_DASHBOARD_FETCH_RECIEVED,
} from '../utils/constants'

export const stateKey = 'discounts'

let initialState = Immutable.Map({
    discounts: Immutable.List()
})

const DiscountsReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('discounts', Immutable.fromJS(payload.results.discounts))
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('discounts', Immutable.fromJS(payload.discounts))
    }
  });
}

export const getDiscounts = (globalState) => {
  return getDiscountState(globalState).get('discounts')
}

export const getDiscountState = (globalState) => globalState[stateKey];
export default DiscountsReducer
