import {
  LOGOUT_USER,
  USER_CREATE_REQUESTED,
  USER_CREATE_RECIEVED,
  USER_CREATE_FAILED,
  USER_FETCH_REQUESTED,
  USER_FETCH_RECIEVED,
  USER_FETCH_FAILED,
  USER_UPDATE_REQUESTED,
  USER_UPDATE_RECIEVED,
  USER_UPDATE_FAILED,
} from '../utils/constants'
import { fetchAction, simpleDispatchAction } from '../utils/actions'
import { shouldFetchUser, localStorageKey } from '../reducers/user_reducer'

export function fetchUser(userId, onSuccess) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchUser(state, userId)
    },
    requestParams: {
      method: 'GET',
      url: `/users/${userId}`,
    },
    onSuccess,
    requestAction: () => { return { type: USER_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: USER_FETCH_RECIEVED, payload: results, userId } },
    failureAction: (error) => { return { type: USER_FETCH_FAILED, payload: { error } } }
  });
}

export function logoutCurrentUser(){
  window.localStorage.removeItem(localStorageKey)
  return simpleDispatchAction({
    action: () => { return { type: LOGOUT_USER } },
  })
}

export function createUser({
  email,
  entryInformation,
  password,
  firstName,
  lastName,
  phone,
  street,
  unit,
  zipCode,
  bedrooms,
  bathrooms,
  specialInstructions,
  onSuccess,
  onFailure
}){
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/users`,
      data: {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        phone,
        address: {
          street,
          unit,
          zip_code: zipCode,
          bedrooms,
          bathrooms,
          special_instructions: specialInstructions,
          entry_info: entryInformation,
        }
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: USER_CREATE_REQUESTED } },
    receivedAction: (results) => { return { type: USER_CREATE_RECIEVED, results } },
    failureAction: (error) => { return { type: USER_CREATE_FAILED, payload: { error } } }
  });
}

export function updateUser({
  params,
  userId,
  onSuccess,
  onFailure
}){
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'PUT',
      url: `/users/${userId}`,
      data: params
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: USER_UPDATE_REQUESTED } },
    receivedAction: (results) => { return { type: USER_UPDATE_RECIEVED, results, userId } },
    failureAction: (error) => { return { type: USER_UPDATE_FAILED, payload: { error } } }
  });
}
