import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import moment from 'moment'
import { parseDate, dayMonthRange, getPreviousMonday } from '../../utils/date_helper'
import {
  getBookingsWindowStart,
  getBookingsWindowEnd,
  getRegionFilter
} from '../../reducers/admin_dashboard_reducer'
import { getAdminBookings, isFetching } from '../../reducers/bookings_reducer'
import {
  fetchAdminBookings,
  updateAdminBookingsWindow,
  updateRegionFilter,
  searchUsers
} from '../../actions/admin_dashboard_actions'
import { getRegions } from '../../reducers/regions_reducer'
import AdminDashboardWeeklyBookings from '../../components/admin_dashboard/AdminDashboardWeeklyBookings'
import { Badge } from 'reactstrap'
import { CSVLink } from "react-csv";
import { flatten, sortBy, values } from 'lodash'
import AsyncSelect from 'react-select/async';
import { navigate } from '@reach/router'

import chevron from '../../assets/icons/chevron-green.svg'
import '../../styles/containers/admin_dashboard.scss'

class AdminDashboardContainer extends Component {
  state = {
    currentUserSearch: undefined,
    openAllDays: false
  }

 componentDidMount() {
   const { windowStart, windowEnd, fetchAdminBookings } = this.props
   fetchAdminBookings({
     windowStart,
     windowEnd
   })
 }

  render() {
    return (
      <React.Fragment>
        { this.renderDateToggle() }
        { this.renderUserAutocomplete() }
        { this.renderCSVDownload() }
        { this.renderRegionFilters() }
        { this.renderOpenAllDaysToggle() }
        { this.renderBookings() }
      </React.Fragment>
    )
  }

  renderOpenAllDaysToggle = () => {
    const { openAllDays } = this.state
    let text;
    if(openAllDays){
      text = 'Close All Days'
    } else {
      text = 'Open All Days'
    }
    return <div className='btn btn-primary' onClick={ this.toggleAllDaysOpen }>{text}</div>
  }

  toggleAllDaysOpen = () => {
    this.setState({ openAllDays: !this.state.openAllDays })
  }

  renderUserAutocomplete = () => {
    return(
      <AsyncSelect
        cacheOptions
        loadOptions={this.loadUserSearchOptions}
        defaultOptions
        onChange={ (option) => { navigate(`/users/${option.value}`) } }
        placeholder="Search by first/last name or email"
      />
    )
  }

  loadUserSearchOptions = (inputValue, callback) => {
    const { searchUsers } = this.props
    const { currentUserSearch } = this.state
    if(!inputValue || inputValue == '') {
      return
    }
    clearTimeout(currentUserSearch)
    this.setState({
      currentUserSearch: setTimeout(() => {
        searchUsers(inputValue, (users) => {
          callback(users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name} - ${user.email}`}
          }))
        })
      }, 1000)
    })
  }

  renderCSVDownload = () => {
    const { bookings, windowStart, windowEnd } = this.props

    if(bookings) {
      const csvData = [[
        'Customer Name',
        'Customer Email',
        'Date',
        'Time',
        'Address',
        'Zip',
        'Region',
        'Bedrooms',
        'Bathrooms',
        'Addons',
        'Team Note',
      ]]
      sortBy(flatten(values(bookings)), ['start_date', 'start_time']).forEach((booking) => {
        csvData.push([
          `${booking.user.first_name} ${booking.user.last_name}`,
          booking.user.email,
          booking.start_date,
          booking.start_time,
          `${booking.address.street} ${booking.address.unit}`,
          booking.address.zip_code,
          booking.region,
          booking.address.bedrooms,
          booking.address.bathrooms,
          booking.addons.to_s,
          booking.team_note
        ])
      })

      return (
        <div style={{ 'textAlign': 'left' }}>
          <CSVLink
            data={csvData}
            target="_blank"
            filename={`${dayMonthRange(windowStart, windowEnd)} bookings`}
          >
            Download CSV
          </CSVLink>
        </div>
      )
    } else {
      return null
    }
  }

  renderBookings = () => {
    const { bookings } = this.props
    const { openAllDays } = this.state
    if(bookings) {
      return <AdminDashboardWeeklyBookings weeklyBookings={ bookings } openAllDays={ openAllDays }/>
    } else {
      return null
    }
  }

  renderDateToggle = () => {
    const { windowStart, windowEnd } = this.props
    return (
      <div>
        <div className='admin-dashboard-window-toggle'>
          <img className='rotate-180' src={chevron} onClick={ () => { this.updateWindow(false) }} />
          <h4>{ dayMonthRange(windowStart, windowEnd) }</h4>
          <img src={chevron} onClick={ () => { this.updateWindow(true) }} />
        </div>
      </div>
    )
  }

  renderRegionFilters = () => {
    const { regions } = this.props
    const filters = regions.map((region) => {
      return this.renderRegionFilter(region.get('name'))
    }).toJS()
    filters.unshift(this.renderRegionFilter('All Regions'))
    return (
      <div className='admin-bookings-region-filters'>
        { filters }
      </div>
    )
  }

  renderRegionFilter = (name) => {
    const { regionFilter, updateRegionFilter } = this.props
    let value, customClass
    if(name === 'All Regions') {
      value = undefined
      customClass = !regionFilter ? 'active' : ''
    } else {
      value = name
      customClass = regionFilter === name ? 'active': ''
    }

    return (
      <h5 onClick={ () => { updateRegionFilter(value) } }>
        <Badge className={ `admin-bookings-region-filter ${customClass}` }>
          {name}
        </Badge>
      </h5>
    )
  }

  updateWindow = (forwards) => {
    const {
      fetchAdminBookings,
      isFetchingBookings,
      updateAdminBookingsWindow,
      windowStart,
      windowEnd
    } = this.props
    const days = forwards ? 7 : -7
    if(isFetchingBookings){
      return
    }

    windowStart.setDate(windowStart.getDate() + days)
    windowEnd.setDate(windowEnd.getDate() + days)
    updateAdminBookingsWindow(windowStart, windowEnd)
    fetchAdminBookings({
      windowStart,
      windowEnd
    })
  }
}

const mapStateToProps = (state, props) => {
  const windowStart = getBookingsWindowStart(state)
  const windowEnd = getBookingsWindowEnd(state)
  const bookings = getAdminBookings(state, windowStart)
  return {
    bookings,
    isFetchingBookings: isFetching(state),
    regions: getRegions(state),
    regionFilter: getRegionFilter(state),
    windowStart,
    windowEnd,
  }
}

const mapDispatchToProps = {
  fetchAdminBookings,
  updateAdminBookingsWindow,
  updateRegionFilter,
  searchUsers
}

AdminDashboardContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardContainer)
