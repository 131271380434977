import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  SELECTED_BOOKING_ADDRESS_UPDATE,
} from '../utils/constants'


export const stateKey = 'selected_booking_address'

let initialState = Immutable.Map({
  selectedAddressId: undefined
})

const SelectedBookingAddressReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [SELECTED_BOOKING_ADDRESS_UPDATE]: (state, payload) => {
      return state.set('selectedAddressId', payload.addressId)
    }
  });
}

export const getSelectedBookingAddress = (globalState) => {
  return getSelectedBookingState(globalState).get('selectedAddressId')
}

export const getSelectedBookingState = (globalState) => globalState[stateKey];
export default SelectedBookingAddressReducer
