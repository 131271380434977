import moment from 'moment'

// Expects format YYYY-MM-DD
export function parseDate(stringDate) {
  return new Date(stringDate + "T00:00:00")
}

export function formatCalendarDay (stringDate) {
  return parseDate(stringDate).getDate()
}

export function wDayLongMonthDayDate (stringDate) {
  return moment(stringDate).format('dddd, MMMM Do')
}

export function formatTime(stringTime) {
  return moment(stringTime, 'HH:mm:00').format('h:mm A')
}

export function shortDate(stringDate) {
  return moment(stringDate).format('MMM D')
}

export function apiDateFormat(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function dayMonthRange(start, end) {
  // If range spans same month
  if(start.getMonth() === end.getMonth()){
    return `${moment(start).format('MMM D')} - ${moment(end).format('D')}`
  // If range spans multiple months
  } else {
    return `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`
  }
}

export function getPreviousMonday() {
    const date = new Date()
    const day = date.getDay()
    const prevMonday = new Date()
    if(day !== 0) {
        prevMonday.setDate(date.getDate() - (day-1))
    }

    return prevMonday
}

export function getAllPossibleBookingTimes(){
  const times = []
  const currentTime = moment('2021-01-01 6:00:00')
  for (let i = 0; i < 30; i++) {
    times.push(currentTime.format('HH:mm:00'))
    currentTime.add('30', 'minutes')
  }
  return times
}

export function hoursTillBooking(booking) {
  return moment.duration(moment(`${booking.startDate}T${booking.startTime}`).diff(moment())).asHours()
}
