import Immutable from 'immutable'
import { createReducer, shouldFetchValue } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_REQUESTED,
  BOOKING_CALENDAR_FETCH_RECIEVED,
  BOOKING_CALENDAR_FETCH_FAILED,
  BOOKING_DELETE_RECIEVED,
  BOOKING_SUBMISSION_RECIEVED,
  BOOKING_UPDATE_RECIEVED,
  ONBOARDING_CALENDAR_FETCH_REQUESTED,
  ONBOARDING_CALENDAR_FETCH_RECIEVED,
  ONBOARDING_CALENDAR_FETCH_FAILED,
  SET_BOOKING_CALENDAR_OVERRIDE_MODE,
  SUBSCRIPTION_DELETE_RECIEVED
} from '../utils/constants'

export const stateKey = 'booking-calendar'

let initialState = Immutable.Map({
  isFetching: false,
  isStale: false,
  retryCount: 0,
  calendars: {},
  onboardingCalendar: null,
  adminOverrideMode: false
})

const BookingCalendarReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_CALENDAR_FETCH_REQUESTED]: (state) => state.merge({isFetching: true}),
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.merge({ isFetching: false, retryCount: 0 })
          .mergeIn(['calendars', payload.addressId], payload.results.calendar)
    },
    [BOOKING_CALENDAR_FETCH_FAILED]: (state) => {
      return state.merge({
        isFetching: true,
        retryCount: state.get('retryCount') + 1
      })
    },
    [BOOKING_SUBMISSION_RECIEVED]: (state) => {
      // wipe calendar
      return initialState
    },
    [SUBSCRIPTION_DELETE_RECIEVED]: (state) => {
      // wipe calendar
      return initialState
    },
    [BOOKING_DELETE_RECIEVED]: (state) => {
      // wipe calendar
      return initialState
    },
    [BOOKING_UPDATE_RECIEVED]: (state) => {
      // wipe calendar
      return initialState
    },
    [SET_BOOKING_CALENDAR_OVERRIDE_MODE]: (state, { value }) => {
      return state.set('adminOverrideMode', value)
    },
    [ONBOARDING_CALENDAR_FETCH_REQUESTED]: (state) => state.merge({isFetching: true}),
    [ONBOARDING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.merge({ isFetching: false, retryCount: 0, onboardingCalendar: Immutable.Map(payload.results.calendar)})
    },
  });
}

export const getBookingCalendarState = (globalState) => globalState[stateKey];

export const getBookingCalendar = (globalState, addressId) => {
  return getBookingCalendarState(globalState).getIn(['calendars', addressId])
}

export const getOnboardingCalendar = (globalState) => {
  return getBookingCalendarState(globalState).get('onboardingCalendar')
}

export const getCalendarOverrideMode = (globalState) => {
  return getBookingCalendarState(globalState).get('adminOverrideMode')
}

export const shouldFetchBooking = (globalState, addressId) => {
  return shouldFetchValue(getBookingCalendarState(globalState), ['calendars', addressId], 3)
}

export default BookingCalendarReducer
