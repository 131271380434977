export function userFriendlyName(frequency){
  switch(frequency) {
  case 'bi_weekly':
    return 'Bi-Weekly'
  case 'monthly':
    return 'Monthly'
  case 'weekly':
    return 'Weekly'
  }
}

export function frequencyText(frequency){
  switch(frequency) {
  case 'bi_weekly':
    return '2 weeks'
  case 'monthly':
    return '4 weeks'
  case 'weekly':
    return 'week'
  }
}
