import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { updateUser } from '../../actions/user_actions'
import { toggleModal } from '../../actions/modal_actions'
import { Alert } from 'reactstrap'
import '../../styles/containers/modal/team_note_modal_container.scss'

class TeamNoteModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: undefined,
      note: props.user.get('recurringNote')
    }
  }

  render() {
    const { error, note } = this.state
    let errorMessage = error ? <Alert color="danger">{ error }</Alert> : null
    return (
      <div className='team-note-modal-container'>
        <textarea
          onChange={ this.updateNote }
          placeholder="This note will show up on every booking, unless you add an additional booking note"
        >
          { note }
        </textarea>
        <button className='btn btn-primary' onClick={ this.saveNote }>
          Save
        </button>
        { errorMessage }
      </div>
    )
  }

  updateNote = (e) => {
    this.setState({note: e.currentTarget.value})
  }

  saveNote = () => {
    const {
      toggleModal,
      updateUser,
      user,
    } = this.props
    const { note } = this.state
    updateUser({
      userId: user.get('id'),
      params: {
        recurring_note: note
      },
      onSuccess: () => { toggleModal() },
      onFailure: () => { this.setState({ error: 'Uh Oh! Something went wrong. Please try again.' }) },
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = {
  toggleModal,
  updateUser
}

TeamNoteModalContainer.propTypes = {
  user: ImmutablePropTypes.map.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamNoteModalContainer)
