import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BookingCalendarDay from './BookingCalendarDay'
import { wDayLongMonthDayDate, formatTime, getAllPossibleBookingTimes } from '../../utils/date_helper'

export default class BookingCalendarRow extends Component {

  render() {
    return (
      <div className='booking-calendar-row-container'>
        <div className='booking-calendar-row'>
          { this.renderRows() }
        </div>
        { this.renderTimeSelection() }
      </div>
    )
  }

  renderRows = () => {
    const {
      adminCalendarOverrideMode,
      row,
      month,
      bookingCalendarMonth,
      selectedDate,
      setDate
    } = this.props
    return row.map((date, index) => {
      return <BookingCalendarDay
          adminCalendarOverrideMode={ adminCalendarOverrideMode }
          key={ `${date}-${index}-day` }
          date={ date }
          month={ month }
          timesCount={ date ? bookingCalendarMonth[date].length : 0 }
          selectedDate={ selectedDate }
          setDate={ setDate }
        />
    })
  }

  renderTimeSelection = () => {
    const { row, selectedDate, setTime } = this.props
    if(selectedDate && row.indexOf(selectedDate) > -1){
      return (
        <div className='booking-calendar-row-time-selection'>
          Available times for { wDayLongMonthDayDate(selectedDate) }
          <br/>
          <select name="time" id="time" onChange={ setTime }>
            { this.renderTimes() }
          </select>
        </div>
      )
    } else {
      return null;
    }
  }

  renderTimes = () => {
    const { adminCalendarOverrideMode, bookingCalendarMonth, selectedDate } = this.props
    const times = [<option>Select A Time</option>]
    if(adminCalendarOverrideMode){
      getAllPossibleBookingTimes().forEach((time) => {
        times.push(<option value={time}>{formatTime(time)}</option>)
      })
    } else {
      bookingCalendarMonth[selectedDate].forEach((time) => {
        times.push(<option value={time}>{formatTime(time)}</option>)
      })
    }
    return times
  }
}

BookingCalendarRow.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  bookingCalendarMonth: PropTypes.object,
  month:  PropTypes.string,
  row: PropTypes.array,
  selectedDate: PropTypes.string,
  setDate: PropTypes.func,
  setTime: PropTypes.func
};
