import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import { composeValidators } from '../../utils/validations'
import '../../styles/components/forms/basic_form.scss'
import { Row, Col } from 'reactstrap'

export default class BasicForm extends Component {

  render() {
    const {
      isSaving,
      onSubmit,
      submitCustomClass,
      submitDisabled,
      submitText,
      submittingText
    } = this.props
    const submitForm = async (values) => {
      onSubmit(values)
    };
    const buttonContent = !isSaving ? (submitText || 'Submit') : (
      <React.Fragment>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      { submittingText || 'Saving...' }
      </React.Fragment>
    )
    return (
      <div className='basic-form'>
        <Form
          initialValues={ this.getInitialState() }
          onSubmit={submitForm}
          render={({ handleSubmit, form, values, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                { this.renderFields() }
                <div className={ submitCustomClass || 'col-12' }>

                  <button
                    className='btn btn-primary'
                    type="submit"
                    disabled={ submitDisabled || invalid }
                  >
                    { buttonContent }
                  </button>
                </div>
              </Row>
            </form>
          )}
        />
      </div>

    )
  }

  getInitialState = () => {
    const { fields } = this.props
    const initialValues = {}
    fields.forEach((field) => {
      if(field.value){
        initialValues[field.name] = field.value
      }
    })
    return initialValues
  }

  renderFields = () => {
    const { fields } = this.props
    return fields.map((field) => {
      return (
        <div className={ field.customClass || 'col-12' }>
          <Field key={field.name}  name={ field.name } validate={composeValidators(...field.validations)}>
          {({ input, meta }) => {
            return this.renderField(field, input, meta)
          }}
          </Field>
        </div>
      )
    })
  }

  renderField = (field, input, meta) => {
    const isInvalid = meta.error && meta.touched
    const inputClass = `form-control ${isInvalid ? 'is-invalid': ''}`
    // Input Error
    let errorSpan
    if(isInvalid){
      errorSpan = <span className='form-error-label'>{ meta.error }</span>
    }

    // Input
    let inputField
    if(field.type === 'textarea') {
      inputField = this.renderTextArea(field, input, inputClass)
    } else {
      inputField = this.renderTextField(field, input, inputClass)
    }

    // Label
    let label
    if(field.label){
      label = <label className="control-label">{ field.label }</label>
    }
    return (
      <div className='form-field'>
        { label }
        { inputField }
        { errorSpan }
      </div>
    )
  }

  renderTextArea = (field, input, inputClass) => {
    return (
      <textarea
        {...input}
        className={inputClass}
        placeholder={field.placeholder}
      />
    )
  }

  renderTextField = (field, input, inputClass) => {
    return (
      <input
        {...input}
        type={field.type || "text"}
        disabled={ field.disabled }
        className={inputClass}
        placeholder={field.placeholder}
      />
    )
  }

}

BasicForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    customClass: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    validations: PropTypes.array.isRequired
  })).isRequired,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitCustomClass: PropTypes.number,
  submitDisabled: PropTypes.bool.isRequired,
  submitText: PropTypes.string,
  submittingText: PropTypes.string
};
