import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  clearBookingDateAndTime,
  updateSelectedDate,
  updateSelectedTime
} from '../../actions/upsert_booking_actions'
import {
  getSelectedAddressId,
  getSelectedDate,
} from '../../reducers/upsert_booking_reducer'
import { getBookingCalendar, getCalendarOverrideMode } from '../../reducers/booking_calendar_reducer'
import { getCurrentUser } from '../../reducers/user_reducer'
import BookingCalendar from '../../components/bookings/BookingCalendar'

class BookingCalendarContainer extends Component {
  render() {
    const {
      adminCalendarOverrideMode,
      bookingCalendar,
      clearDay,
      date,
      setDate,
      setTime,
    } = this.props

    return (
      <BookingCalendar
        adminCalendarOverrideMode={ adminCalendarOverrideMode }
        bookingCalendar={ bookingCalendar }
        clearDay={ clearDay }
        date={ date }
        setDate={ setDate }
        setTime={ setTime }
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const currentUser = getCurrentUser(state)
  const selectedAddressId = getSelectedAddressId(state)
  return {
    adminCalendarOverrideMode: getCalendarOverrideMode(state),
    bookingCalendar: getBookingCalendar(state, selectedAddressId),
    date: getSelectedDate(state),
  }
}

const mapDispatchToProps = {
  clearDay: clearBookingDateAndTime,
  setDate: updateSelectedDate,
  setTime: updateSelectedTime
}

BookingCalendarContainer.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  bookingCalendar: ImmutablePropTypes.map.isRequired,
  clearDay: PropTypes.func.isRequired,
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  setTime: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCalendarContainer)
