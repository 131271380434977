import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wDayLongMonthDayDate, formatTime, shortDate } from '../../utils/date_helper'
import { Alert } from 'reactstrap'
import { frequencyText } from '../../utils/subscription_helper'

export default class BookingCheckout extends Component {
  render() {
    const { cleaningPrice, bookingDate, bookingTime, discountCode } = this.props
    const discountWording = <div className='checkout-total'><div className='checkout-price'>You saved ${cleaningPrice.discountAmount} on this booking with your referral code</div></div>
    return (
      <div className='checkout'>
        <div className='checkout-title'>Checkout</div>
          <div className='checkout-message'>
            <div className='checkout-total'>
              { this.renderPriceMessage() }
            </div>
            <div>
              { cleaningPrice.discountAmount ? discountWording : '' }
            </div>
          </div>
        { this.renderButton() }
        { this.renderBookingError() }
      </div>
    )
  }

  renderPriceMessage = () => {
    const { bookingDate, bookingId, bookingTime, bookingType, cleaningPrice, discountCode } = this.props
    if (bookingId) {
      let pricingText;
      if(cleaningPrice.totalHighPrice) {
        pricingText = `You will be charged between $${ cleaningPrice.totalLowPrice.toFixed(2) } - $${ cleaningPrice.totalHighPrice.toFixed(2) }`
      } else {
        pricingText = `Your new total is ${ cleaningPrice.totalLowPrice.toFixed(2) }.`
      }
      return (
        <React.Fragment>
          Reschedule your booking to {wDayLongMonthDayDate(bookingDate)} @ {formatTime(bookingTime)}?
          <br/>
          { pricingText }.
        </React.Fragment>
      )
    } else if(cleaningPrice.totalHighPrice) {
      return (
        <React.Fragment>
            On {wDayLongMonthDayDate(bookingDate)} @ {formatTime(bookingTime)} you will be charged between
            <div className='checkout-price'>
              ${ cleaningPrice.totalLowPrice.toFixed(2) } - ${ cleaningPrice.totalHighPrice.toFixed(2) }
            </div>
        </React.Fragment>
      )
    } else {
      const wording = frequencyText(bookingType)

      return (
        <React.Fragment>
          At your first cleaning on {shortDate(bookingDate)} at {formatTime(bookingTime)} you will be charged ${ cleaningPrice.totalLowPrice.toFixed(2) }.
          <br/>
          You will then be charged ${ (cleaningPrice.totalLowPrice + cleaningPrice.discountAmount).toFixed(2) } every { wording } at future appointments.
          <br/>
          There is no long-term agreement, you may modify or cancel your appointments at any time.
          <br/>
          Note: Your card will not be charged until after your cleaning
        </React.Fragment>
      )
    }
  }

  renderButton = () => {
    const { bookingId, isSubmittingBooking, submitDisabled } = this.props
    if(isSubmittingBooking){
      return (
        <div className='btn btn-primary' disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        Booking...
        </div>
      )
    } else {
      const className = `btn btn-primary ${submitDisabled ? 'disabled': ''}`
      const text = bookingId ? 'Update Booking' : 'Book Cleaning'
      return (
        <div
          className={ className }
          onClick={ this.submitBooking }
        >
          { text }
        </div>
      )
    }
  }

  submitBooking = () => {
    const { submitBooking, submitDisabled } = this.props
    if(!submitDisabled) {
      submitBooking()
    }
  }

  renderBookingError = () => {
    const { bookingError } = this.props
    if(bookingError){
      return (
        <Alert color="danger">
          { bookingError }
        </Alert>
      )
    } else {
      return null
    }
  }
}

BookingCheckout.propTypes = {
  bookingDate: PropTypes.string.isRequired,
  bookingId: PropTypes.number,
  bookingError: PropTypes.string,
  bookingTime: PropTypes.string.isRequired,
  bookingType: PropTypes.string.isRequired,
  cleaningPrice: PropTypes.object.isRequired,
  discountCode: PropTypes.string,
  isSubmittingBooking: PropTypes.bool.isRequired,
  submitBooking: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
};
