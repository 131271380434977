import {
  BOOKING_SUBMISSION_REQUESTED,
  BOOKING_SUBMISSION_RECIEVED,
  BOOKING_SUBMISSION_FAILED,
  BOOKING_UPDATE_REQUESTED,
  BOOKING_UPDATE_RECIEVED,
  BOOKING_UPDATE_FAILED,
  BOOKING_DELETE_REQUESTED,
  BOOKING_DELETE_RECIEVED,
  BOOKING_DELETE_FAILED,
  BOOKING_FETCH_REQUESTED,
  BOOKING_FETCH_RECIEVED,
  BOOKING_FETCH_FAILED,
  BOOKING_COMPLETION_REQUESTED,
  BOOKING_COMPLETION_RECIEVED,
  BOOKING_COMPLETION_FAILED,
  BOOKING_TEST_RECEIPT_EMAIL_REQUESTED,
  BOOKING_TEST_RECEIPT_EMAIL_RECEIVED,
  BOOKING_TEST_RECEIPT_EMAIL_FAILED
} from '../utils/constants'

import { fetchAction } from '../utils/actions'
import { shouldFetchBooking, getBookingsState } from '../reducers/bookings_reducer'

export function completeBooking({
  bookingId,
  charged_amount,
  onFailure,
  onSuccess,
  userId,
  windowStart,
}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return getBookingsState(state).get('isFetching') !== true
    },
    requestParams: {
      method: 'PUT',
      url: `/users/${userId}/bookings/${bookingId}/complete`,
      data: {
        charged_amount: parseFloat(charged_amount)
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: BOOKING_COMPLETION_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_COMPLETION_RECIEVED, payload: results, windowStart } },
    failureAction: (error) => { return { type: BOOKING_COMPLETION_FAILED, payload: { error } } }
  });
}

export function submitBooking({
  addressId,
  addonIds,
  creditCardId,
  date,
  discountCode,
  onSuccess,
  onFailure,
  prices,
  time,
  type,
  userId
}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/users/${userId}/bookings`,
      data: {
        addon_price: prices.addonPrice,
        addons: addonIds,
        address: addressId,
        base_low_price: prices.baseLowPrice,
        base_high_price: prices.baseHighPrice,
        credit_card: creditCardId,
        date,
        discount_amount: prices.discountAmount || 0,
        discount_code: discountCode,
        time,
        total_low_price: prices.totalLowPrice,
        total_high_price: prices.totalHighPrice,
        type
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: BOOKING_SUBMISSION_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_SUBMISSION_RECIEVED, payload: results, userId } },
    failureAction: (error) => { return { type: BOOKING_SUBMISSION_FAILED, payload: { error } } }
  });
}

export function updateBooking({
  addressId,
  addonIds,
  bookingId,
  creditCardId,
  date,
  onSuccess,
  onFailure,
  prices,
  time,
  teamNote,
  userId
}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'PUT',
      url: `/users/${userId}/bookings/${bookingId}`,
      data: {
        addon_price: prices.addonPrice,
        addons: addonIds,
        address: addressId,
        base_low_price: prices.baseLowPrice,
        base_high_price: prices.baseHighPrice,
        credit_card: creditCardId,
        date,
        team_note: teamNote,
        time,
        total_low_price: prices.totalLowPrice,
        total_high_price: prices.totalHighPrice
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: BOOKING_UPDATE_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_UPDATE_RECIEVED, payload: results, userId } },
    failureAction: (error) => { return { type: BOOKING_UPDATE_FAILED, payload: { error } } }
  });
}

export function deleteBooking({
  bookingId,
  userId,
  onSuccess,
  onFailure,
}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'DELETE',
      url: `/users/${userId}/bookings/${bookingId}`,
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: BOOKING_DELETE_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_DELETE_RECIEVED, payload: results, userId } },
    failureAction: (error) => { return { type: BOOKING_DELETE_FAILED, payload: { error } } }
  });
}


export function fetchBooking(bookingId, onSuccess) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchBooking(state, bookingId)
    },
    requestParams: {
      method: 'GET',
      url: `/bookings/${bookingId}`,
    },
    onSuccess,
    requestAction: () => { return { type: BOOKING_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_FETCH_RECIEVED, payload: results } },
    failureAction: (error) => { return { type: BOOKING_FETCH_FAILED, payload: { error } } }
  });
}

export function sendTestReceiptEmail(bookingId) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/bookings/${bookingId}/test_receipt_email`,
    },
    requestAction: () => { return { type: BOOKING_TEST_RECEIPT_EMAIL_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_TEST_RECEIPT_EMAIL_RECEIVED } },
    failureAction: (error) => { return { type: BOOKING_TEST_RECEIPT_EMAIL_FAILED, payload: { error } } }
  });
}
