import { compact, find, values } from 'lodash'
import { getState } from './store'
import { getRegionByZipcode } from '../reducers/regions_reducer'
import { getDiscounts } from '../reducers/discounts_reducer'
import { getAddons } from '../reducers/addons_reducer'
import { getUserAddresses } from '../reducers/address_reducer'

const REFERRAL_DISCOUNT_PERCENTAGE = 0.1
const SUBSCRIPTION_TYPES = ['bi_weekly', 'monthly', 'weekly']
export function getPriceForExistingBooking(booking, selectedAddonIds) {
  const addonPrice = getAddonsPrice(selectedAddonIds)
  return {
    addonPrice,
    baseLowPrice: booking.baseLowPrice,
    baseHighPrice: booking.baseHighPrice,
    discountAmount: booking.discountCode ? booking.lowPrice * REFERRAL_DISCOUNT_PERCENTAGE : 0,
    totalLowPrice: booking.baseLowPrice + addonPrice,
    totalHighPrice: booking.baseHighPrice ? (booking.baseHighPrice + addonPrice) : undefined,
  }
}

export function getPriceForNewBooking(bookingType, discountCode, selectedAddressId, selectedAddonIds, userId) {
  const globalState = getState()
  const addresses = getUserAddresses(globalState, userId)
  const address = getAddressFromId(addresses, selectedAddressId)
  const units = address.bedrooms + address.bathrooms
  const region = getRegionByZipcode(globalState, address.zipCode)
  const breakpoint = getBreakpoint(bookingType, region, units)
  const discounts = getDiscounts(globalState)

  return calculatePrices(breakpoint, discounts, units, discountCode, bookingType, selectedAddonIds)
}

export function getAddonsPrice(selectedAddonIds) {
  const addons = getAddons(getState())
  let addonPrice = 0
  addons.forEach((addon) => {
    if(selectedAddonIds.includes(addon.get('id'))){
      addonPrice += addon.get('price')
    }
  })
  return addonPrice
}

function getAddressFromId(addresses, selectedAddress){
  return addresses.find((address) => address.id === selectedAddress)
}

function getBreakpoint(bookingType, region, units) {
  const breakpointType = SUBSCRIPTION_TYPES.includes(bookingType) ? 'subscription' : bookingType
  const breakpoints = compact(values(region.get(`${breakpointType}_breakpoints`).get('breakpoints').toJS())).filter((value) => {
    return typeof(value) == 'object'
  })
  let matchingBreakpoint = find(breakpoints, (breakpoint) => {
    return breakpoint.number_of_rooms === units
  })
  if(!matchingBreakpoint) {
    matchingBreakpoint = breakpoints[breakpoints.length - 1]
  }
  return matchingBreakpoint
}

function calculatePrices(breakpoint, discounts, units, discountCode, bookingType, selectedAddonIds) {
  const discount = getTypeDiscountPercentage(discounts, bookingType)
  const addonPrice = getAddonsPrice(selectedAddonIds)
  let lowPrice = ((breakpoint.low_price || breakpoint.unit_price) * units)*(1 - discount)
  let highPrice;
  let discountAmount = 0
  if(breakpoint.high_price) {
    highPrice = (breakpoint.high_price * units)*(1 - discount)
  } else if(discountCode) {
    discountAmount = lowPrice * .1
    lowPrice -= discountAmount
  }

  return {
    addonPrice,
    baseLowPrice: lowPrice,
    baseHighPrice: highPrice,
    discountAmount,
    totalLowPrice: lowPrice + addonPrice,
    totalHighPrice: highPrice ? (highPrice + addonPrice) : undefined,
  }
}

function getTypeDiscountPercentage(discounts, bookingType) {
  const relevantDiscount = discounts.filter((discount) => discount.get('type') === bookingType).get(0)
  if(relevantDiscount){
    return relevantDiscount.get('percentage') / 100
  } else {
    return 0
  }
}
