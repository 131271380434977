export function getQueryParam(title, params) {
  const match = params.location.search.match(RegExp(title + '=.+'))
  if(match) {
    return match[0].split('=')[1]
  }
}

export function extractQueryParams(routeProps) {
  const queryString = routeProps.location.search
  const queryParams = {}
  queryString.slice(1).split('&').forEach((param) => {
    const split = param.split(/=(.+)/)
    queryParams[split[0]] = split[1]
  })
  return queryParams
}

export function extractIds(routeProps) {
  const routeIds = {}
  const idKeys = Object.keys(routeProps).filter((key) => key.includes('id') || key.includes('Id'))
  idKeys.forEach((key) => {
    routeIds[key] = routeProps[key]
  })
  return routeIds
}

export function buildRedirectUrl(routeProps){
  if(!routeProps || !routeProps.redirectPath) {
    return null
  }
  const qp = buildRedirectQueryParams(routeProps, true)
  if(routeProps.redirectPath.indexOf('?') > -1 && qp.length > 0) {
    return `${routeProps.redirectPath}&${buildRedirectQueryParams(routeProps, true)}`
  } else if(qp.length > 0) {
    return `${routeProps.redirectPath}?${buildRedirectQueryParams(routeProps, true)}`
  } else {
    return routeProps.redirectPath
  }
}

export function buildRedirectQueryParams(routeProps, ignoreRedirectPath){
  let keys = Object.keys(routeProps)
  if(ignoreRedirectPath){
    keys = keys.filter((key) => key !== 'redirectPath')
  }

  if(keys.length === 0){
    return ''
  }

  let redirectUrl = ''
  keys.forEach((key, index) => {
    if(key !== ""){
      redirectUrl += `${key}=${routeProps[key]}`

      // Append & if not the last item
      if(key !== keys[keys.length -1]){
        redirectUrl += '&'
      }
    }
  })
  return redirectUrl
}
