import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { updateBooking } from '../../actions/bookings_actions'
import { toggleModal } from '../../actions/modal_actions'
import { Alert } from 'reactstrap'
import '../../styles/containers/modal/team_note_modal_container.scss'

class TeamNoteModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: undefined,
      note: props.booking.teamNote
    }
  }

  render() {
    const { error, note } = this.state
    let errorMessage = error ? <Alert color="danger">{ error }</Alert> : null
    return (
      <div className='team-note-modal-container'>
        <textarea onChange={ this.updateNote }>
          { note }
        </textarea>
        <button className='btn btn-primary' onClick={ this.saveNote }>
          Save
        </button>
        { errorMessage }
      </div>
    )
  }

  updateNote = (e) => {
    this.setState({note: e.currentTarget.value})
  }

  saveNote = () => {
    const {
      booking,
      toggleModal,
      updateBooking,
      userId,
    } = this.props
    const { note } = this.state
    updateBooking({
      bookingId: booking.id,
      onSuccess: () => { toggleModal() },
      onFailure: () => { this.setState({ error: 'Uh Oh! Something went wrong. Please try again.' }) },
      prices: {},
      teamNote: note,
      userId
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = {
  toggleModal,
  updateBooking
}

TeamNoteModalContainer.propTypes = {
  bookingId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamNoteModalContainer)
