import React, { Component } from 'react';
import { connect } from 'react-redux'
import { isModalOpen, getModalProps, getModalComponent } from '../../reducers/modal_reducer'
import { toggleModal } from '../../actions/modal_actions'
import { Modal, ModalBody } from 'reactstrap';

import NewAddressModalContainer from './NewAddressModalContainer'
import NewCreditCardModalContainer from './NewCreditCardModalContainer'
import SubscriptionSelectionModalContainer from './SubscriptionSelectionModalContainer'
import BookingAddonManagementModalContainer from './BookingAddonManagementModalContainer'
import TeamNoteModalContainer from './TeamNoteModalContainer'
import CompleteBookingModalContainer from './CompleteBookingModalContainer'
import RecurringTeamNoteModalContainer from './RecurringTeamNoteModalContainer'
import UserUpdaterModalContainer from './UserUpdaterModalContainer'
import SubscriptionCancellationModal from './SubscriptionCancellationModal'
import SubscriptionCreditCardSelectionModalContainer from './SubscriptionCreditCardSelectionModalContainer'

class ModalContainer extends Component {
  render() {
    const { isOpen, toggleModal } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggleModal} className='test'>
        <ModalBody>
          { this.renderModalComponent() }
        </ModalBody>
      </Modal>
    )
  }

  renderModalComponent = () => {
    const { component, modalProps } = this.props
    switch(component) {
      case 'BookingAddonManagementModalContainer':
        return <BookingAddonManagementModalContainer {...modalProps } />
      case 'NewAddressModalContainer':
        return <NewAddressModalContainer {...modalProps } />
      case 'NewCreditCardModalContainer':
        return <NewCreditCardModalContainer {...modalProps } />
      case 'SubscriptionSelectionModalContainer':
        return <SubscriptionSelectionModalContainer {...modalProps } />
      case 'TeamNoteModalContainer':
        return <TeamNoteModalContainer {...modalProps } />
      case 'CompleteBookingModalContainer':
        return <CompleteBookingModalContainer {...modalProps} />
      case 'RecurringTeamNoteModalContainer':
        return <RecurringTeamNoteModalContainer {...modalProps} />
      case 'UserUpdaterModalContainer':
        return <UserUpdaterModalContainer {...modalProps} />
      case 'SubscriptionCancellationModal':
        return <SubscriptionCancellationModal {...modalProps} />
      case 'SubscriptionCreditCardSelectionModalContainer':
        return <SubscriptionCreditCardSelectionModalContainer {...modalProps} />
      default:
        return ""
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    component: getModalComponent(state),
    isOpen: isModalOpen(state),
    modalProps: getModalProps(state),
  }
}

const mapDispatchToProps = {
  toggleModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
