import { simpleDispatchAction } from '../utils/actions'
import {
  CLEAR_BOOKING_DATE_AND_TIME,
  UPDATE_SELECTED_ADDON_IDS,
  UPDATE_SELECTED_ADDRESS_ID,
  UPDATE_SELECTED_CREDIT_CARD_ID,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_TIME,
} from '../utils/constants'

export function clearBookingDateAndTime() {
  return simpleDispatchAction({
    action: () => { return { type: CLEAR_BOOKING_DATE_AND_TIME } },
  })
}

export function updateSelectedAddonIds(value) {
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_SELECTED_ADDON_IDS, value } },
  })
}

export function updateSelectedAddressId(option) {
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_SELECTED_ADDRESS_ID, value: option.value } },
  })
}

export function updateSelectedCreditCardId(option) {
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_SELECTED_CREDIT_CARD_ID, value: option.value } },
  })
}

export function updateSelectedDate(value) {
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_SELECTED_DATE, value } },
  })
}

export function updateSelectedTime(value) {
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_SELECTED_TIME, value } },
  })
}
