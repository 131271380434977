import { fetchAction, simpleDispatchAction } from '../utils/actions'
import { shouldFetchBooking } from '../reducers/booking_calendar_reducer'
import {
  BOOKING_CALENDAR_FETCH_REQUESTED,
  BOOKING_CALENDAR_FETCH_RECIEVED,
  BOOKING_CALENDAR_FETCH_FAILED,
  ONBOARDING_CALENDAR_FETCH_REQUESTED,
  ONBOARDING_CALENDAR_FETCH_RECIEVED,
  ONBOARDING_CALENDAR_FETCH_FAILED,
  SET_BOOKING_CALENDAR_OVERRIDE_MODE
} from '../utils/constants'

export function fetchBookingCalendar(addressId, userId, discount, onSuccess) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchBooking(state, addressId)
    },
    requestParams: {
      method: 'GET',
      url: `/users/${userId}/bookings_calendar?address_id=${addressId}&discount_code=${discount}`,
    },
    onSuccess,
    requestAction: () => { return { type: BOOKING_CALENDAR_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: BOOKING_CALENDAR_FETCH_RECIEVED, payload: { results, addressId, userId } } },
    failureAction: (error) => { return { type: BOOKING_CALENDAR_FETCH_FAILED, payload: { error } } }
  });
}

export function fetchOnboardingBookingCalendar(zip, bedrooms, bathrooms) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchBooking(state, 'onboarding')
    },
    requestParams: {
      method: 'GET',
      url: `/onboarding_calendar?zip_code=${zip}&bedrooms=${bedrooms}&bathrooms=${bathrooms}`,
    },
    requestAction: () => { return { type: ONBOARDING_CALENDAR_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: ONBOARDING_CALENDAR_FETCH_RECIEVED, payload: { results } } },
    failureAction: (error) => { return { type: ONBOARDING_CALENDAR_FETCH_FAILED, payload: { error } } }
  });
}

export function setBookingCalendarOverrideMode(value) {
  return simpleDispatchAction({
    action: () => { return { type: SET_BOOKING_CALENDAR_OVERRIDE_MODE, value } },
  })
}
