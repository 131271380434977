import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import AddToCalendar from 'react-add-to-calendar';
import { connect } from 'react-redux'
import { getUserBookings } from '../../reducers/bookings_reducer'
import { getCurrentUser } from '../../reducers/user_reducer'
import { getUserSubscriptions } from '../../reducers/subscription_reducer'
import { formatTime, shortDate, hoursTillBooking } from '../../utils/date_helper'
import { navigate } from "@reach/router"
import { toggleModal } from '../../actions/modal_actions'
import { cancelBooking } from '../../utils/booking_actions_helper'
import { complimentaryAddons } from '../../utils/addons_helper'
import { intersection, orderBy } from 'lodash'
import moment from 'moment-timezone'
import { Row, Col } from 'reactstrap';

import editIcon from '../../assets/icons/pencil.svg'
import deleteIcon from '../../assets/icons/trash.svg'

class UserBookingsContainer extends Component {

  deleteBooking = (booking) => {
    const { user } = this.props
    cancelBooking(booking.id, user.get('id'))
  }

  redirectToNewBookings = () => {
    const { currentUser, user } = this.props
    if(currentUser.get('id') === user.get('id')) {
      navigate('/bookings?type=one_time')
    } else {
      navigate(`/users/${user.get('id')}/bookings?type=one_time`)
    }
  }

  redirectToEditBooking = (booking) => {
    navigate(`/bookings/${booking.id}`)
  }

  render() {
    return (
      <div className='user-dashboard-bookings'>
        <div className='user-dashboard-bookings-header'>
          <h5>Your Upcoming Bookings</h5>
          { this.renderOneTimeBooking() }
        </div>
        { this.renderBookingsTable() }
        <div
          className='btn btn-primary recurring'
          onClick={ this.openRecurringTeamNoteModal }
        >
          Add Recurring Note To All Bookings
        </div>
      </div>
    )
  }

  renderOneTimeBooking = () => {
    return this.props?.subscriptions?.size == 0 ? null :
    (
      <div className='right-option'>
        <a onClick={ this.redirectToNewBookings }><h5>Add New Booking</h5></a>
      </div>
    )
  }

  renderActions = (booking) => {
    const hoursTill = hoursTillBooking(booking)
    if(hoursTill > 36){
      return (
        <div className='user-booking-actions'>
          <div
            onClick={ () => this.redirectToEditBooking(booking) }
            className='btn btn-primary btn-sm action-button'>
              Edit
          </div>
          <div
            onClick={ () => this.deleteBooking(booking) }
            className='btn btn-primary btn-sm action-button'>
              Cancel
          </div>
          <div
            onClick={ () => this.openTeamNoteModal(booking) }
            className='btn btn-primary btn-sm action-button'>
              Send Note To Team
          </div>
          <AddToCalendar
            event={this.calendarEvent(booking)}
          />
        </div>
      )
    } else if (hoursTill > 0) {
      return "Please call our office at 800-791-2878 to update bookings within 36 hours. Late cancellation fees may apply."
    } else {
        return "No changes can be made to past bookings"
    }
  }

  calendarEvent = (booking) => {
    const date = moment(booking.startDate).format('MMMM Do, YYYY')
    const address = booking.address
    const description = `You have an appointment for a cleaning of ${address.bedrooms} bedrooms, ${address.bathrooms} on ${date} by GoWellNest. We may arrive 1 hour prior to your scheduled time to 1 hour after your scheduled time. We will arrive at approximately 8 AM for 8 AM appointments. Please plan accordingly. Check our FAQ for further information on the Arrival Window.`
    return {
      title: `WellNest appointment on ${date}`,
      description,
      location: 'Your Place!',
      startTime: moment.tz(`${booking.startDate} ${booking.startTime}`, "America/New_York").format('YYYY-MM-DDTHH:mm:00Z'),
      endTime: moment.tz(`${booking.startDate} ${booking.endTime}`, "America/New_York").format('YYYY-MM-DDTHH:mm:00Z')
    };
  }

  renderBookingsTable = () => {
    const { bookings } = this.props
    return (
      <React.Fragment>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Appointment Time</th>
              <th scope="col">Address</th>
              <th scope="col">Payment</th>
              <th scope="col">Addons</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            { this.renderBookings() }
          </tbody>
        </table>
        { bookings.length === 0 ? <div><b>You Currently Have No Future Bookings</b></div> : '' }
      </React.Fragment>
    )
  }

  renderAddons = (booking) => {
    const addons = booking.addons
    const newButton = (
      <div
        onClick={ () => this.openAddonModal(booking) }
        className='btn btn-primary btn-sm'>
          Add Addons
      </div>
    )
    if(complimentaryAddons(booking.type)) {
      return <div>Deep cleaning package included</div>
    } else if (addons.length) {
      return (
        <div className='user-dashboard-addons'>
          { addons.map((addon) => {  return <div>{ addon.shortTitle }</div>  }) }
          { newButton }
        </div>
      )
    } else {
      return newButton
    }
  }

  renderBookings = () => {
    const { bookings } = this.props

    return bookings.map((booking) => {
      return (
        <tr>
          <td>
            { shortDate(booking.startDate) }
            <br/>
            { formatTime(booking.startTime) }
          </td>
          <td>
            { booking.address.street }
          </td>
          <td>
            XXXX - { booking.creditCard.lastFour }
          </td>
          <td>
            { this.renderAddons(booking) }
          </td>
          <td>{ this.renderActions(booking) }</td>
        </tr>
      )
    })
  }

  openAddonModal = (booking) => {
    const { toggleModal, user } = this.props
    toggleModal('BookingAddonManagementModalContainer', {
      bookingId: booking.id,
      userId: user.get('id'),
      selectedAddonIds: booking.addons.map((a) => a .id)
    })
  }

  openTeamNoteModal = (booking) => {
    const { toggleModal, user } = this.props
    toggleModal('TeamNoteModalContainer', {
      booking: booking,
      userId: user.get('id')
    })
  }

  openRecurringTeamNoteModal = (booking) => {
    const { toggleModal, user } = this.props
    toggleModal('RecurringTeamNoteModalContainer', {
      user
    })
  }

  updateBooking = (booking) => {

  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: getCurrentUser(state),
    bookings: orderBy(getUserBookings(state, props.user.get('id')), ['startDate', 'startTime']),
    subscriptions: getUserSubscriptions(state, props.user.get('id'))
  }
}

const mapDispatchToProps = {
  toggleModal
}

UserBookingsContainer.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBookingsContainer)
