import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';
import BookingCalendarMonth from './BookingCalendarMonth'
import { Row, Col } from 'reactstrap';
import '../../styles/booking_calendar.scss'

export default class BookingCalendar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      month: undefined,
    }
  }

  render() {
    const { month } = this.state
    const { bookingCalendar, clearDay, date, setTime} = this.props
    return (
      <div className='booking-calendar'>
        <Row>
          { this.renderMonths() }
        </Row>
      </div>
    )
  }

  renderMonths = () => {
    const {
      adminCalendarOverrideMode,
      date,
      bookingCalendar,
      setTime
    } = this.props
    let [ ...months ] = bookingCalendar.keys();
    return months.map((calendarMonth) => {
      return (
        <Col lg="6" md="12" className='booking-calendar-month' key={calendarMonth}>
          <BookingCalendarMonth
            adminCalendarOverrideMode={ adminCalendarOverrideMode }
            bookingCalendarMonth={ bookingCalendar.get(calendarMonth) }
            monthTitle={ calendarMonth }
            selectedDate={ date }
            setDate={ this.setDate }
            setTime={ this.setTime }
          />
        </Col>
      )
    })
  }

  setDate = (date, month) => {
    const { setDate } = this.props
    this.setState({month})
    setDate(date)
  }

  setTime = (e) => {
    this.props.setTime(e.currentTarget.value)
  }
}

BookingCalendar.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  bookingCalendar: ImmutablePropTypes.map.isRequired,
  clearDay: PropTypes.func.isRequired,
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  setTime: PropTypes.func.isRequired,
};
