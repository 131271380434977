import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { updateSelectedAddressId } from '../actions/upsert_booking_actions'
import { deleteAddress } from '../actions/address_actions'
import { getCurrentUser, getUser } from '../reducers/user_reducer'
import { getUserAddresses } from '../reducers/address_reducer'
import { getSelectedAddressId } from '../reducers/upsert_booking_reducer'
import { toggleModal } from '../actions/modal_actions'
import AddressDropdown from '../components/AddressDropdown'
import AddressTable from '../components/AddressTable'
import Swal from 'sweetalert2'

class UserAddressesContainer extends Component {
  render() {
    const { type } = this.props;
    const addressComponent = type == 'select' ? this.renderSelect() : this.renderTable()
    return (
      <React.Fragment>
        { addressComponent }
        { this.renderAddNewAddress() }
      </React.Fragment>
    )
  }

  renderSelect = () => {
    const {
      addresses,
      label,
      placeholder,
      selectedAddress,
      setBookingAddress
    } = this.props

    return (
      <React.Fragment>
        <AddressDropdown
          addresses={ addresses }
          label={ label }
          placeholder={ placeholder }
          selectedAddress={ selectedAddress }
          setAddress={ setBookingAddress }
        />
      </React.Fragment>
    )
  }

  renderTable = () => {
    const {
      addresses,
      label
    } = this.props

    return (
      <React.Fragment>
        <AddressTable
          addresses={ addresses }
          label={ label }
          deleteAddress={ this.onAddressDelete }
        />
      </React.Fragment>
    )
  }

  renderAddNewAddress = () => {
    const { toggleModal, user } = this.props
    return (
      <div
        className='add-new-address underline'
        onClick={ () => toggleModal('NewAddressModalContainer', { userId: user.get('id') }) }
      >
        + Add New Address
      </div>
    )
  }

  onAddressDelete = (addressId) => {
    const { addresses, deleteAddress, user } = this.props

    if(addresses.size == 1) {
      Swal.fire(
        'Whoops!',
        'You must have at least one address. Try adding a new address before deleting this one.',
        'error'
      )
      return
    }

    Swal.fire({
      title: 'Are you sure you want to delete this address?',
      text: 'This will not impact any existing bookings with this address',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAddress(user.get('id'), addressId, () => {
          Swal.fire(
            'Deleted!',
            'Your address has been deleted.',
            'success'
          )
        }, (error) => {
          Swal.fire(
            'Whoops!',
            'There was an error trying to delete your address. Please try again.',
            'error'
          )
        })
      }
    })
  }
}

const mapStateToProps = (state, props) => {
  const { userId } = props
  const user = userId ? getUser(state, userId) : getCurrentUser(state)
  const addresses = getUserAddresses(state, user.get('id'))
  return {
    addresses,
    selectedAddress: getSelectedAddressId(state),
    user
  }
}

const mapDispatchToProps = {
  setBookingAddress: updateSelectedAddressId,
  toggleModal,
  deleteAddress: deleteAddress,
}

UserAddressesContainer.propTypes = {
  label: PropTypes.element,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  userId: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddressesContainer)
