import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { updateSelectedCreditCardId } from '../actions/upsert_booking_actions'
import { getCurrentUser, getUser } from '../reducers/user_reducer'
import { getUserCreditCards } from '../reducers/credit_cards_reducer'
import { deleteCreditCard } from '../actions/credit_card_actions'
import { getSelectedCreditCardId } from '../reducers/upsert_booking_reducer'
import { toggleModal } from '../actions/modal_actions'
import CreditCardList from '../components/CreditCardList'
import CreditCardTable from '../components/CreditCardTable'
import Swal from 'sweetalert2'
import '../styles/credit_card_list.scss'

class UserCreditCardsContainer extends Component {
  render() {
    const {
      selectMode,
      type
    } = this.props

    const cardComponent = type == 'table' ? this.renderTableMode() : this.renderSelectMode()
    return (
      <React.Fragment>
        { cardComponent }
        { this.renderAddNewCC() }
        <div className='credit-card-disclaimer'>
          Credit cards stored and processed by Stripe, a PCI-compliant processing service with the highest certification standard possible.
        </div>
      </React.Fragment>
    )
  }

  renderSelectMode = () => {
    const {
      label,
      creditCards,
      selectedCreditCard,
      setBookingCreditCard,
      toggleModal,
      user
    } = this.props

    return (
      <CreditCardList
        addNewCard = {() => toggleModal('NewCreditCardModalContainer', {
          userId: user.get('id'),
          onCreationSuccess: this.onCreationSuccess
        }) }
        label={ label }
        creditCards={ creditCards }
        selectedCreditCard={ selectedCreditCard }
        setCreditCard={ setBookingCreditCard }
      />
    )
  }

  renderTableMode = () => {
    const { creditCards, deleteCreditCard } = this.props
    return <CreditCardTable
      creditCards={ creditCards }
      deleteCreditCard={ this.deleteCreditCard }
    />
  }


  deleteCreditCard = (creditCardId) => {
    const { creditCards, deleteCreditCard, userId } = this.props

    if(creditCards.size == 1) {
      Swal.fire(
        'Whoops!',
        'You must have at least one credit card. Try adding a new address before deleting this one.',
        'error'
      )
      return
    }

    Swal.fire({
      title: 'Are you sure you want to delete this credit card?',
      text: 'This will not impact any existing bookings with this card',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCreditCard(creditCardId, userId, () => {
          Swal.fire(
            'Deleted!',
            'Your credit card has been deleted.',
            'success'
          )
        }, (error) => {
          Swal.fire(
            'Whoops!',
            'There was an error trying to delete your credit card. Please try again.',
            'error'
          )
        })
      }
    })
  }

  renderAddNewCC = () => {
    const { toggleModal, user } = this.props
    return (
      <div
        className='add-new-credit-card underline'
        onClick={ () => { toggleModal('NewCreditCardModalContainer',
          {
            userId: user.get('id'),
            onCreationSuccess: this.onCreationSuccess
          }) }}
      >
        + Add New Credit Card
      </div>
    )
  }

  onCreationSuccess = (cc) => {
    const { selectMode, setBookingCreditCard } = this.props
    if(selectMode){
      setBookingCreditCard({ value: cc.id })
    }
  }
}

const mapStateToProps = (state, props) => {
  const { userId } = props
  const user = userId ? getUser(state, userId) : getCurrentUser(state)
  return {
    creditCards: getUserCreditCards(state, user.get('id')),
    selectedCreditCard: getSelectedCreditCardId(state),
    user
  }
}

const mapDispatchToProps = {
  deleteCreditCard,
  setBookingCreditCard: updateSelectedCreditCardId,
  toggleModal
}

UserCreditCardsContainer.propTypes = {
  label: PropTypes.element,
  selectMode: PropTypes.bool.isRequired,
  userId: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreditCardsContainer)
