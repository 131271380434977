import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { redirectTo } from "@reach/router"
import { submitFeedback } from '../actions/feedback_actions'
import BasicForm from '../components/forms/BasicForm'
import icon from '../assets/icons/checkmark_circle_green.svg'
import { Alert } from 'reactstrap'


class FeedbackContainer extends Component {
  constructor(props){
    super()

    this.state = {
      error: false,
      submitting: false,
      submittedDescription: false
    }
  }

  componentDidMount(){
    const { bookingId, rating, submitFeedback } = this.props

    if(!['happy', 'neutral', 'unhappy'].includes(rating) || !bookingId){
      window.location = '/'
    }

    submitFeedback({
      bookingId,
      feedback: { rating },
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  sendDescription = (input) => {
    const { bookingId, rating, submitFeedback } = this.props
    this.setState({ submitting: true, error: false })
    submitFeedback({
      bookingId,
      feedback: { rating, description: input.description },
      onSuccess: () => {
        this.setState({
          submitting: false,
          submittedDescription: true
        })
    },
    onFailure: () => {
      this.setState({
        submitting: false,
        error: true
      })
    }
  })
}

  render() {
    const { error, submitting, submittedDescription } = this.state

    let errorComponent
    if(error) {
      errorComponent = (
        <Alert color="danger">
          Uh Oh! Something went wrong and we couldn't submit your feedback. Please try again
        </Alert>
      )
    }

    let form
    if(!submittedDescription){
      form = (
        <BasicForm
          fields={ [
            {
              label: 'Any Specifics You Want To Share?',
              name: 'description',
              placeholder: 'Elaborate on anything you loved, or things we can improve on',
              type: 'textarea',
              validations: []
            },
          ] }
          onSubmit={ this.sendDescription }
          submitDisabled={ false }
          isSaving={ submitting }
        />
      )
    }

    return (
      <React.Fragment>
        <h2 className='primary-color' style={{'paddingTop': '50px', 'paddingBottom': '20px'}}>Thank You For Submitting Feedback</h2>
        <img src={icon} style={{ 'width': '200px' }}/>
        { form }
        { errorComponent }
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state, props) => {
  const { routeProps } = props
  return {
    bookingId: routeProps.booking_id,
    rating: routeProps.rating
  }
}

const mapDispatchToProps = {
  submitFeedback
}

FeedbackContainer.propTypes = {
  routeProps: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer)
