import Swal from 'sweetalert2'
import { getDispatch } from './store'
import { deleteBooking } from '../actions/bookings_actions'
import { updateSelectedDate, updateSelectedTime } from '../actions/upsert_booking_actions'
import { navigate } from '@reach/router'

export function cancelBooking(bookingId, userId){
  const onSuccess = () => {
    Swal.fire(
      'Cancelled!',
      'Your booking has been cancelled.',
      'success'
    )
  }

  const onFailure = () => {
    Swal.fire(
      'Uh Oh!',
      'Something went wrong trying to cancel your booking. Please try again',
      'error'
    )
  }

  Swal.fire({
    title: 'Are you sure you want to cancel this booking?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, cancel it!'
  }).then((result) => {
    if (result.isConfirmed) {
      getDispatch()(deleteBooking({
        bookingId,
        userId,
        onSuccess,
        onFailure
      }))
    }
  })
}
