import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';

export default class AddressTable extends Component {

  render() {
    const { label, placeholder } = this.props
    return (
      <div className='address-selection'>
        { label }
        <table class="table">
          { this.renderAddresses() }
        </table>
      </div>
    );
  }

  renderAddresses = () => {
    const { addresses, deleteAddress } = this.props

    return addresses.map ((address) => {
      let title = address.street

      if(address.unit) {
        title += " " + address.unit + " "
      }

      title += " " + address.city + ", " + address.zipCode

      return (
        <tr>
          <td>
           { title }
          </td>
          <td>
            <div
              onClick={ () => deleteAddress(address.id) }
              class='btn btn-primary btn-sm action-button'>
              Delete
            </div>
          </td>
        </tr>
      )
    })
  }
}

AddressTable.propTypes = {
  addresses: ImmutablePropTypes.list.isRequired,
  placeholder: PropTypes.string,
  deleteAddress: PropTypes.func
};
