import {
  ADDRESSES_FETCH_REQUESTED,
  ADDRESSES_FETCH_RECIEVED,
  ADDRESSES_FETCH_FAILED,
  ADDRESSES_SUBMISSION_REQUESTED,
  ADDRESSES_SUBMISSION_RECIEVED,
  ADDRESSES_SUBMISSION_FAILED,
  DELETE_ADDRESS_REQUESTED,
  DELETE_ADDRESS_RECIEVED,
  DELETE_ADDRESS_FAILED
} from '../utils/constants'

import { fetchAction } from '../utils/actions'
import { shouldFetchAddresses } from '../reducers/address_reducer'

export function fetchUserAddresses(userId) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchAddresses(state, userId)
    },
    requestParams: {
      method: 'GET',
      url: `/users/${userId}/addresses`,
    },
    requestAction: () => { return { type: ADDRESSES_FETCH_REQUESTED } },
    receivedAction: (results) => { return { type: ADDRESSES_FETCH_RECIEVED, payload: { results, userId } } },
    failureAction: (error) => { return { type: ADDRESSES_FETCH_FAILED, payload: { error } } }
  });
}

export function submitUserAddress(address, userId, onSuccess, onFailure) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/users/${userId}/addresses`,
      data: { address }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: ADDRESSES_SUBMISSION_REQUESTED } },
    receivedAction: (results) => { return { type: ADDRESSES_SUBMISSION_RECIEVED, address: results, userId } },
    failureAction: (error) => { return { type: ADDRESSES_SUBMISSION_FAILED, payload: { error } } }
  });
}

export function deleteAddress(userId, addressId, onSuccess, onFailure) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'DELETE',
      url: `/users/${userId}/addresses/${addressId}`
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: DELETE_ADDRESS_REQUESTED } },
    receivedAction: (results) => { return { type: DELETE_ADDRESS_RECIEVED, userId, addressId } },
    failureAction: (error) => { return { type: DELETE_ADDRESS_FAILED, payload: { error } } }
  });
}
