import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Swal from 'sweetalert2'
import { navigate } from '@reach/router'
import { cancelBooking } from '../../utils/booking_actions_helper'
import { getBookingsWindowStart, getRegionFilter } from '../../reducers/admin_dashboard_reducer'
import AdminDashboardDailyBookings from '../../components/admin_dashboard/AdminDashboardDailyBookings'
import { completeBooking } from '../../actions/bookings_actions'
import { toggleModal } from '../../actions/modal_actions'

class AdminDashboardDailyBookingsContainer extends Component {

  render() {
    const {
      dailyBookings,
      date,
      openAllDays,
      regionFilter
    } = this.props

    let bookings = dailyBookings
    if(regionFilter) {
      bookings = dailyBookings.filter((booking) => booking.region === regionFilter)
    }

    return (
      <AdminDashboardDailyBookings
        dailyBookings={ bookings }
        date={ date }
        openAllDays={ openAllDays }
        redirectToUserDashboard={ this.redirectToUserDashboard }
        completeBooking={ this.openCompleteBookingToggle }
        deleteBooking={ this.deleteBooking }
        editBooking={ this.editBooking }
      />
    )
  }

  openCompleteBookingToggle = (booking) => {
    const { toggleModal } = this.props
    toggleModal('CompleteBookingModalContainer', { booking })
  }

  redirectToUserDashboard = (booking) => {
    navigate(`/users/${booking.user.id}`)
  }

  deleteBooking = (booking) => { cancelBooking(booking.id, booking.user.id) }
  editBooking = (booking) => { navigate(`/bookings/${booking.id}`) }
}

const mapStateToProps = (state, props) => {
  return {
    regionFilter: getRegionFilter(state),
    windowStart: getBookingsWindowStart(state)
  }
}

const mapDispatchToProps = {
  completeBooking,
  toggleModal
}

AdminDashboardDailyBookingsContainer.propTypes = {
  openAllDays: PropTypes.bool,
  dailyBookings: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardDailyBookingsContainer)
