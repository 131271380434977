import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  BOOKING_SUBMISSION_RECIEVED,
  USER_DASHBOARD_FETCH_RECIEVED,
  SUBSCRIPTION_DELETE_RECIEVED,
  SUBSCRIPTION_UPDATE_RECIEVED
} from '../utils/constants'

export const stateKey = 'subscriptions'

let initialState = Immutable.Map({
    subscriptions: Immutable.Map({})
})

const SubscriptionReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_SUBMISSION_RECIEVED]: (state, { payload, userId }) => {
      const subscription = payload.subscription
      // If subscription already present in candidate's sub list
      if(getUserSubscriptionIndex(state, userId, subscription.id) < 0){
        return state
      // If subscription does not exist in candidate's list
      } else {
        const newSubscriptions = state.getIn(['subscriptions', userId]).push(Immutable.Map(subscription))
        return state.setIn(['subscriptions', userId], newSubscriptions)
      }
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload, userId }) => {
      const subscriptions = Immutable.fromJS(payload.subscriptions.sort((a,b) => a.id - b.id ))
      return state.setIn(['subscriptions', userId], subscriptions)
    },
    [SUBSCRIPTION_DELETE_RECIEVED]: (state, { userId, subscriptionId }) => {
      const subscriptionIndex = getUserSubscriptionIndex(state, userId, subscriptionId)
      const newSubscriptions = state.getIn(['subscriptions', userId]).delete(subscriptionIndex)
      return state.setIn(['subscriptions', userId], newSubscriptions)
    },
    [SUBSCRIPTION_UPDATE_RECIEVED]: (state, { payload, userId, subscriptionId }) => {
      const subscriptions = state.getIn(['subscriptions', userId])
      const subscriptionIndex = subscriptions.findIndex((sub) => sub.get('id') == subscriptionId)
      return state.setIn(['subscriptions', userId, subscriptionIndex], Immutable.fromJS(payload))
    }
  });
}

export const getUserSubscriptionIndex = (state, userId, subscriptionId) => {
  return state.getIn(['subscriptions', userId], Immutable.List()).findIndex((sub) => sub.get('id') == subscriptionId)
}

export const getUserSubscriptions = (globalState, userId) => {
  return getSubscriptionState(globalState).getIn(['subscriptions', userId]);
}

export const getSubscriptionState = (globalState) => globalState[stateKey];
export default SubscriptionReducer
