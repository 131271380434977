import Immutable from 'immutable'
import { createReducer, shouldFetchValue } from '../utils/reducers'
import {
  BOOKING_SUBMISSION_REQUESTED,
  BOOKING_SUBMISSION_RECIEVED,
  BOOKING_SUBMISSION_FAILED,
  USER_DASHBOARD_FETCH_REQUESTED,
  USER_DASHBOARD_FETCH_RECIEVED,
  BOOKING_UPDATE_REQUESTED,
  BOOKING_UPDATE_RECIEVED,
  BOOKING_UPDATE_FAILED,
  BOOKING_DELETE_RECIEVED,
  BOOKING_FETCH_REQUESTED,
  BOOKING_FETCH_RECIEVED,
  BOOKING_FETCH_FAILED,
  FETCH_ADMIN_BOOKINGS_REQUESTED,
  FETCH_ADMIN_BOOKINGS_RECIEVED,
  FETCH_ADMIN_BOOKINGS_FAILED,
  BOOKING_COMPLETION_REQUESTED,
  BOOKING_COMPLETION_RECIEVED,
  BOOKING_COMPLETION_FAILED,
  SUBSCRIPTION_DELETE_RECIEVED,
} from '../utils/constants'
import { apiDateFormat } from '../utils/date_helper'

export const stateKey = 'bookings'

let initialState = Immutable.Map({
  isFetching: false,
  user_bookings: Immutable.Map({}),
  bookings: Immutable.Map({}),
  adminBookings: Immutable.Map({})
})

const BookingsReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [FETCH_ADMIN_BOOKINGS_REQUESTED]: (state) => state.merge({isFetching: true}),
    [FETCH_ADMIN_BOOKINGS_RECIEVED]: (state, { windowStart, results }) => {
      return state.merge({isFetching: false})
      .setIn(['adminBookings', apiDateFormat(windowStart)], results.bookings)
    },
    [FETCH_ADMIN_BOOKINGS_FAILED]: (state) => state.merge({isFetching: false}),
    [BOOKING_SUBMISSION_REQUESTED]: (state) => state.merge({isFetching: true}),
    [BOOKING_SUBMISSION_RECIEVED]: (state, { payload, userId }) => {
      return state.merge({isFetching: false})
      .setIn(['user_bookings', userId], payload.bookings)
      .set('bookings', updateBookingsMapFromUserBookingsArray(state.get('bookings'), payload.bookings))
    },
    [BOOKING_SUBMISSION_FAILED]: (state) => state.merge({isFetching: false}),
    [USER_DASHBOARD_FETCH_REQUESTED]: (state) => state.merge({isFetching: true}),
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload, userId }) => {
      return state.merge({isFetching: false}).setIn(['user_bookings', userId], payload.bookings)
      .set('bookings', updateBookingsMapFromUserBookingsArray(state.get('bookings'), payload.bookings))
    },
    [BOOKING_UPDATE_REQUESTED]: (state) => state.merge({isFetching: true}),
    [BOOKING_UPDATE_RECIEVED]: (state, { payload, userId }) => {
      return state.merge({isFetching: false})
      .setIn(['user_bookings', userId], payload.bookings )
      .set('bookings', updateBookingsMapFromUserBookingsArray(state.get('bookings'), payload.bookings))
    },
    [BOOKING_UPDATE_FAILED]: (state) => state.merge({ isFetching: false }),
    [BOOKING_DELETE_RECIEVED]: (state, { payload, userId }) => {
      return state.merge({isFetching: false})
      .setIn(['user_bookings', userId], payload.bookings )
    },
    [BOOKING_FETCH_REQUESTED]: (state) => state.merge({ isFetching: true }),
    [BOOKING_FETCH_RECIEVED]: (state, { payload }) => {

      return state.merge({ isFetching: false }).setIn(['bookings', payload.id], payload)
    },
    [BOOKING_FETCH_FAILED]: (state) => {
      return state.merge({ isFetching: false })
    },
    [BOOKING_COMPLETION_REQUESTED]: (state) => state.merge({ isFetching: true }),
    [BOOKING_COMPLETION_RECIEVED]: (state, { payload, windowStart }) => {
      const path = ['adminBookings', apiDateFormat(windowStart), payload.start_date]
      const arr = state.getIn(path)
      const index = arr.findIndex((i) => i.id === payload.id)
      arr[index] = payload
      return state.merge({ isFetching: false }).setIn(path, arr)
    },
    [BOOKING_COMPLETION_FAILED]: (state) => {
      return state.merge({ isFetching: false })
    },
    [SUBSCRIPTION_DELETE_RECIEVED]: (state, { payload, userId }) => {
      return state.setIn(['user_bookings', userId], payload.bookings)
      .set('bookings', updateBookingsMapFromUserBookingsArray(state.get('bookings'), payload.bookings))
    }
  });
}

const updateBookingsMapFromUserBookingsArray = (existingBookings, userBookings) => {
  userBookings.forEach((booking) => {
    existingBookings = existingBookings.set(booking.id, booking)
  })
  return existingBookings
}

export const isFetchingBookings = (globalState) => {
  return getBookingsState(globalState).get('isFetching');
}

export const shouldFetchBookings = (globalState, userId) => {
  const state = getBookingsState(globalState)
  return state.getIn(['user_bookings', userId.toString()]) === undefined
    && !state.get('isFetching')
}

export const shouldFetchBooking = (globalState, bookingId) => {
  return shouldFetchValue(getBookingsState(globalState), ['bookings', bookingId], 3)
}

export const getUserBookings = (globalState, userId) => {
  return getBookingsState(globalState).getIn(['user_bookings', userId])
}

export const getUserBooking = (globalState, userId, bookingId) => {
  const userBookings = getBookingsState(globalState).getIn(['user_bookings', userId], [])
  const index = userBookings.findIndex((booking) => booking.id === bookingId)
  return userBookings[index]
}

export const getBooking = (globalState, bookingId) => {
  return getBookingsState(globalState).getIn(['bookings', parseInt(bookingId)])
}

export const shouldFetchAdminBookings = (globalState, windowStart) => {
  return shouldFetchValue(getBookingsState(globalState), ['adminBookings', apiDateFormat(windowStart) ], 3)
}

export const getAdminBookings = (globalState, windowStart) => {
  return getBookingsState(globalState).getIn(['adminBookings', apiDateFormat(windowStart)])
}

export const isFetching = (globalState) => {
  return getBookingsState(globalState).get('isFetching')
}

export const getBookingsState = (globalState) => globalState[stateKey];

export default BookingsReducer
