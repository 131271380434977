import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';
import Select from 'react-select-v2'

export default class AddressDropdown extends Component {

  render() {
    const { label, placeholder, selectedAddress, setAddress } = this.props
    const options = this.getOptions()
    const selectedOption = options.filter((option) => option.value === selectedAddress).get(0)
    return (
      <div className='address-selection'>
        { label }
        <Select
          className='address-selection-dropdown'
          options={ options }
          value={ selectedOption }
          onChange={ setAddress }
          placeholder={ placeholder }
        />
      </div>
    );
  }

  getOptions = () => {
    const { addresses } = this.props
    return addresses.map((address) => {
      let label;
      if(address.unit) {
        label = `${address.street} ${address.unit}`
      } else {
        label = `${address.street}`
      }
      return { value: address.id, label }
    })
  }
}

AddressDropdown.propTypes = {
  addresses: ImmutablePropTypes.list.isRequired,
  placeholder: PropTypes.string,
  selectedAddress: PropTypes.number.isRequired,
  setAddress: PropTypes.func.isRequired
};
