import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { redirectTo } from '@reach/router'
import BookingCalendar from '../components/bookings/BookingCalendar'
import SignUpContainer from './authentication/SignUpContainer'
import { fetchOnboardingBookingCalendar } from '../actions/booking_calendar_actions'
import { getOnboardingCalendar } from '../reducers/booking_calendar_reducer'
import { updateSelectedDate, updateSelectedTime } from '../actions/upsert_booking_actions'
import { getSelectedDate, getSelectedTime } from '../reducers/upsert_booking_reducer'
import { navigate } from '@reach/router'

class OnboardingContainer extends Component {

  componentDidMount(){
    const { fetchOnboardingBookingCalendar, routeProps } = this.props
    let missingRequirements = false

    if(!routeProps.zipcode || routeProps.zipcode.length == 0) {
      missingRequirements = true
      window.location = '/signup'
    }

    if(!routeProps.type || routeProps.type.length == 0) {
      missingRequirements = true
      window.location = '/signup'
    }

    if(!missingRequirements){
      fetchOnboardingBookingCalendar(
        routeProps.zipcode,
        routeProps.bedrooms || 1,
        routeProps.bathrooms || 1,
      )
    }
  }

  render() {
    const {
      bookingCalendar,
      date,
      routeProps,
      type,
      updateSelectedDate,
      updateSelectedTime
    } = this.props
    routeProps.redirectPath = `/bookings?type=${type}`
    if(bookingCalendar) {
      return (
        <React.Fragment>
          <BookingCalendar
            adminCalendarOverrideMode={ false }
            bookingCalendar={ bookingCalendar }
            clearDay={ () => {} }
            date={ date }
            setDate={ updateSelectedDate }
            setTime={ this.setTime }
          />
        <h3 id='signup-scroll-point'>Create An Account</h3>
        <SignUpContainer routeProps={ routeProps }/>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  setTime = (val) => {
    const { updateSelectedTime, time, routeProps } = this.props
    if(!time) {
      document.getElementById('signup-scroll-point').scrollIntoView({ behavior: 'smooth' })
    }
    updateSelectedTime(val)
  }
}

const mapStateToProps = (state, props) => {
  return {
    bookingCalendar: getOnboardingCalendar(state),
    date: getSelectedDate(state),
    time: getSelectedTime(state)
  }
}

const mapDispatchToProps = {
  fetchOnboardingBookingCalendar,
  updateSelectedDate,
  updateSelectedTime
}

OnboardingContainer.propTypes = {
  routeProps: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer)
