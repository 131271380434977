import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { toggleModal } from '../../actions/modal_actions'
import { updateSubscription } from '../../actions/subscription_actions'
import { fetchUserDashboard } from '../../actions/user_dashboard_actions'
import { getUserCreditCards } from '../../reducers/credit_cards_reducer'
import CreditCardList from '../../components/CreditCardList'
import { Alert } from 'reactstrap'
import '../../styles/components/modal/new_subscription_type_selection.scss'


class SubscriptionCreditCardSelectionModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCreditCardId: props.subscription.getIn(['creditCard', 'id']),
      sendingRequest: false,
      error: undefined
    }
  }

    render() {
      const { creditCards, subscription, updateSubscription, userId } = this.props
      const { selectedCreditCardId, error } = this.state

      let errorMessage = error ? <Alert color="danger">{ error }</Alert> : null
      return (
        <div className='subscription-type-selection'>
          <h4>What Card Should Be Used?</h4>
          <CreditCardList
            label={ '' }
            creditCards={ creditCards }
            selectedCreditCard={ selectedCreditCardId }
            setCreditCard={ this.setSubscriptionCreditCard }
          />
          <p className='subtitle'>All editable, future subscription bookings will be updated with this card</p>
          { this.renderUpdateButton() }
          { errorMessage }
        </div>
      )
    }

    setSubscriptionCreditCard = (selectedCard) => {
      this.setState({
        selectedCreditCardId: selectedCard.value
      })
    }

    renderUpdateButton = () => {
      const { sendingRequest } = this.state
      const buttonContent = !sendingRequest ? 'Update Subscription' : (
        <React.Fragment>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Updating...
        </React.Fragment>
      )

      return (
        <button
          className='btn btn-primary'
          type="submit"
          disabled={ sendingRequest }
          onClick={ this.updateSubscription }
        >
          { buttonContent }
        </button>
      )
    }

    updateSubscription = () => {
      const { fetchUserDashboard, subscription, toggleModal, updateSubscription, userId } = this.props
      const { selectedCreditCardId } = this.state
      this.setState({ sendingRequest: true })
      updateSubscription({
        subscriptionId: subscription.get('id'),
        userId,
        creditCardId: selectedCreditCardId,
        onSuccess: () => {
          fetchUserDashboard(userId, () => {
            toggleModal()
          })
        },
        onFailure: (error) => {
          this.setState({ sendingRequest: false, error: 'Uh Oh! Something went wrong. Please try again.' })
        }
      })
    }
}

const mapStateToProps = (state, props) => {
  return {
    creditCards: getUserCreditCards(state, props.userId)
  }
}

const mapDispatchToProps = {
  fetchUserDashboard,
  toggleModal,
  updateSubscription
}

SubscriptionCreditCardSelectionModalContainer.propTypes = {
  subscription: ImmutablePropTypes.map.isRequired,
  userId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCreditCardSelectionModalContainer)
