const messages = {
  REQUIRED: 'This field is required',
  NUMBER: 'This field must be numeric',
  EMAIL: 'Valid email is required',
  PHONE: 'Valid phone number is required',
  ZIP: 'Valid US zip code is required',
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const required = value =>
  (value && value !== '') ? undefined : messages.REQUIRED

export const isNumber = value =>
  isNaN(value) ? messages.NUMBER : undefined

export const lengthInRange = (lowRange, highRange, value, message) => {
  if(!(value.length >= lowRange || value.length <= highRange)) {
    return message
  }
}

export const isLength = (length, value, message) => {
  if(value.length !== length) {
    return message
  }
}

export const email = value =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : messages.EMAIL

export const phone = value =>
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value) ? undefined : messages.PHONE

export const zipCode = value =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ? undefined : messages.ZIP
