import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Row, Col } from 'reactstrap'
import { complimentaryAddons } from '../../utils/addons_helper'

export default class BookingAddons extends Component {

  render() {
    return (
      <div className='addons'>
        <div className='addons-title'>Additional Services</div>
        { this.renderAddons() }
      </div>
    )
  }

  renderAddons = () => {
    const { addons, bookingType, selectedAddons } = this.props
    if( complimentaryAddons(bookingType) ){
      return (
        <div className='complimentary-addons'>
          One-Time bookings all include the deep cleaning package (inside oven, inside fridge, and interior windows)
        </div>
      )
    } else {
      return addons.sort((a,b) => b.id - a.id).map((addon) => {
        return (
          <div className='addon-item' key={ addon.get('id') }>
            <div className='addon-top-level'>
              <Row>
                <Col xs="1">
                  <input
                    type='checkbox'
                    onChange={ (e) => this.toggle(e, addon) }
                    checked={ selectedAddons.findIndex((sa) => sa.get('id') == addon.get('id')) > -1 }
                  />
                </Col>
                <Col xs="8">
                  { addon.get('title') }
                </Col>
                <Col xs="2">
                <b>${ Math.round(addon.get('price')) }</b>
              </Col>
              <Col xs={{ size: 8, offset: 1 }} className='addon-subtext'>
                <span>{ addon.get('additionalInfo') }</span>
              </Col>
              </Row>
            </div>
          </div>
        )
      })
    }
  }

  toggle = (e, addon) => {
    const { toggleAddon } = this.props
    toggleAddon(addon, e.currentTarget.checked)
  }
}

BookingAddons.propTypes = {
  addons: ImmutablePropTypes.list.isRequired,
  bookingType: PropTypes.string.isRequired,
  selectedAddons: ImmutablePropTypes.list.isRequired,
  toggleAddon: PropTypes.func.isRequired
};
