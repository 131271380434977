import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_RECIEVED,
  USER_DASHBOARD_FETCH_RECIEVED
} from '../utils/constants'

export const stateKey = 'addons'

let initialState = Immutable.Map({
    addons: Immutable.List()
})

const AddonsReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('addons', Immutable.fromJS(payload.results.addons))
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload, userId }) => {
      return state.set('addons', Immutable.fromJS(payload.addons))
    },
  });
}

export const getAddons = (globalState) => {
  return getAddonState(globalState).get('addons')
}

export const getAddonState = (globalState) => globalState[stateKey];
export default AddonsReducer
