import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable'
import { connect } from 'react-redux'
import { getAddons } from '../../reducers/addons_reducer'
import { isFetchingBookings, getUserBooking } from '../../reducers/bookings_reducer'
import { getRegions } from '../../reducers/regions_reducer'
import { updateBooking } from '../../actions/bookings_actions'
import { remove } from 'lodash'
import { toggleModal } from '../../actions/modal_actions'
import { getPriceForExistingBooking } from '../../utils/price_helper_v2'
import { addonsFromIds } from '../../utils/addons_helper'
import '../../styles/containers/modal/booking_addon_management_modal_container.scss'

class BookingAddonManagementModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: false,
      selectedAddonIds: props.selectedAddonIds
    }
  }

  getPrices = () => {
    const { addons, booking, regions } = this.props
    const { selectedAddonIds } = this.state
    return getPriceForExistingBooking(booking, selectedAddonIds)
  }

  handleInputChange = (e, addon) => {
    const { selectedAddonIds } = this.state
    const checked = e.currentTarget.checked
    if(checked){
      selectedAddonIds.push(addon.get('id'))
    } else {
      remove(selectedAddonIds, (id) => id === addon.get('id'))
    }
    this.setState({ selectedAddonIds })
  }

  isChecked = (addon) => {
    const { selectedAddonIds } = this.state
    return selectedAddonIds.findIndex((id) => id === addon.get('id')) > -1
  }

  render() {
    const { addons, booking } = this.props
    const addonInputs = addons.map((addon) => {
      return this.renderAddon(addon)
    })

    return (
      <div className='addon-update-management-modal'>
        <div className='addon-update-title'>
          <h4>Select Addons</h4>
        </div>
        { addonInputs }
        { this.renderError() }
        { this.renderPrice() }
        { this.renderButton() }
      </div>
    )
  }

  renderAddon = (addon) => {
    return (
      <React.Fragment>
        <label className='addon-update-item'>
          <input
            className='addon-update-item-input'
            name='addons'
            type='checkbox'
            value=''
            checked={ this.isChecked(addon) }
            onChange={ (e) => this.handleInputChange(e, addon) }
          />
        { addon.get('title') } - ${ parseInt(addon.get('price')).toFixed(0) }
        </label>
      </React.Fragment>
    )
  }

  renderButton = () => {
    const { isSaving } = this.props
    if(isSaving){
      return (
        <div className='btn btn-primary' disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        Updating...
        </div>
      )
    } else {
      return (
        <div className='btn btn-primary' onClick={ this.onSubmit }>
          Update Booking
        </div>
      )
    }
  }

  renderError = () => {
    const { error } = this.state
    if(error) {
      return (
        <div className='text-danger'>
          Uh Oh! Something Went Wrong. Please Try Again.
        </div>
      )
    } else {
      return null
    }
  }

  renderPrice = () => {
    const prices = this.getPrices()
    let pricesText;
    if(prices.totalHighPrice) {
      pricesText = `New Total: Between $${prices.totalLowPrice} - $${prices.totalHighPrice}`
    } else {
      pricesText = `New Total: $${prices.totalLowPrice}`
    }

    return (
      <div className='addon-update-price'>
        { pricesText }
      </div>
    )
  }

  onSubmit = () => {
    const { booking, updateBooking, toggleModal, userId } = this.props
    const { selectedAddonIds } = this.state
    this.setState({ error: false })
    const prices = this.getPrices()
    updateBooking({
      addonIds: selectedAddonIds,
      bookingId: booking.id,
      prices,
      userId,
      onSuccess: () => toggleModal(),
      onFailure: () => this.setState({ error: true })
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    addons: getAddons(state),
    booking: getUserBooking(state, props.userId, props.bookingId),
    regions: getRegions(state),
    isSaving: isFetchingBookings(state)
  }
}

const mapDispatchToProps = {
  toggleModal,
  updateBooking
}

BookingAddonManagementModalContainer.propTypes = {
  bookingId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  selectedAddonIds: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingAddonManagementModalContainer)
