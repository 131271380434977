import {
  SUBSCRIPTION_DELETE_REQUESTED,
  SUBSCRIPTION_DELETE_RECIEVED,
  SUBSCRIPTION_DELETE_FAILED,
  SUBSCRIPTION_UPDATE_REQUESTED,
  SUBSCRIPTION_UPDATE_RECIEVED,
  SUBSCRIPTION_UPDATE_FAILED
} from '../utils/constants'
import { fetchAction, simpleDispatchAction } from '../utils/actions'

export function deleteSubscription({subscriptionId, userId, reason, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'DELETE',
      url: `/users/${userId}/subscriptions/${subscriptionId}`,
      data: {
        reason
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: SUBSCRIPTION_DELETE_REQUESTED } },
    receivedAction: (results) => { return { type: SUBSCRIPTION_DELETE_RECIEVED, payload: results, userId, subscriptionId } },
    failureAction: (error) => { return { type: SUBSCRIPTION_DELETE_FAILED, payload: { error } } }
  });
}

export function updateSubscription({subscriptionId, userId, creditCardId, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'PUT',
      url: `/users/${userId}/subscriptions/${subscriptionId}`,
      data: {
        credit_card_id: creditCardId
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: SUBSCRIPTION_UPDATE_REQUESTED } },
    receivedAction: (results) => {
      return { type: SUBSCRIPTION_UPDATE_RECIEVED, payload: results, userId, subscriptionId } 
    },
    failureAction: (error) => { return { type: SUBSCRIPTION_UPDATE_FAILED, payload: { error } } }
  });
}
