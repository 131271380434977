import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keys } from 'lodash'
import { parseDate } from '../../utils/date_helper'

import BookingCalendarRow from './BookingCalendarRow'

export default class BookingCalendarMonth extends Component {

  render() {
    const {
      monthTitle
    } = this.props
    return (
      <React.Fragment>
        <h2 className='booking-calendar-month-title'>{ monthTitle }</h2>
        <div className='booking-calendar-wday-titles booking-calendar-row'>
          <div className='booking-calendar-day'>M</div>
          <div className='booking-calendar-day'>Tu</div>
          <div className='booking-calendar-day'>W</div>
          <div className='booking-calendar-day'>Th</div>
          <div className='booking-calendar-day'>F</div>
        </div>
        { this.renderRows() }
      </React.Fragment>
    )
  }

  renderRows = () => {
    const {
      adminCalendarOverrideMode,
      bookingCalendarMonth,
      monthTitle,
      selectedDate,
      setDate,
      setTime
    } = this.props
    return this.splitIntoRows().map((row) => {
      return <BookingCalendarRow
        adminCalendarOverrideMode={ adminCalendarOverrideMode }
        key={ `${row[0]}-row` }
        row={ row }
        bookingCalendarMonth={ bookingCalendarMonth }
        month={ monthTitle }
        selectedDate={ selectedDate }
        setDate={ setDate }
        setTime={ setTime }
      />
    })
  }

  splitIntoRows = () => {
    const { bookingCalendarMonth } = this.props
    const dates = keys(bookingCalendarMonth).sort()
    const rows = []
    // Get first week
    const firstDay = parseDate(dates[0])
    let index = 6 - firstDay.getDay()
    rows.push(Array.apply(undefined, {length: firstDay.getDay() - 1}).concat(dates.slice(0, index)))
    while(index < dates.length) {
      rows.push(dates.slice(index, index + 5))
      index += 5
    }

    let lastRow = rows.pop()
    if(lastRow.length < 5) {
      lastRow = lastRow.concat(Array.apply(undefined, {length: 5 - lastRow.length}))
    }
    rows.push(lastRow)
    return rows
  }
}

BookingCalendarMonth.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  bookingCalendarMonth: PropTypes.object,
  monthTitle: PropTypes.string,
  selectedDate: PropTypes.string,
  setDate: PropTypes.func,
  setTime: PropTypes.func,
};
