import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import BasicForm from '../../components/forms/BasicForm'
import { Alert } from 'reactstrap'
import { required, email } from '../../utils/validations'
import { loginUser } from '../../actions/authentication_actions'
import { navigate } from '@reach/router'
import { buildRedirectUrl, buildRedirectQueryParams } from '../../utils/route_helper'
import '../../styles/containers/authentication_containers.scss'

class LoginContainer extends Component {
  state = {
    error: undefined
  }

  render() {
    const { routeProps } = this.props
    return (
      <div className='login-container auth-container'>
        <h2>Log In</h2>
        { this.renderError() }
        <BasicForm
          fields={ this.fields() }
          onSubmit={ this.onSubmit }
          submitDisabled={ false }
          isSaving={ false }
        />
        <a href='/forgotten_password' className='login-option'>Forgot your password?</a>
        <br/><br/>
        <a
          href={`/signup?${buildRedirectQueryParams(routeProps, false)}`}
          className='login-option'
        >
          Need a new account? Register here
        </a>
    </div>
    )
  }

  fields(){
    return [
      {
        name: 'email',
        placeholder: 'Email',
        type: 'text',
        validations: [required, email]
      },
      {
        name: 'password',
        placeholder: 'Password',
        type: 'password',
        validations: [required]
      },
    ]
  }

  onSubmit = (formResults) => {
    const { loginUser } = this.props
    loginUser({
      email: formResults.email,
      password: formResults.password,
      onSuccess: (results) => {
        if(results.admin) {
          navigate(buildRedirectUrl() || `admin_dashboard`)
        } else {
          navigate(buildRedirectUrl() || `/users/${results.id}`)
        }
      },
      onFailure: (error) => {
        this.setState({ error: (error && error.message) || 'Your password and email do not match' })
      }
    })
  }

  renderError = () => {
    const { error } = this.state
    if(error) {
      return (
        <Alert color="danger">
          { error }
        </Alert>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, props) => {
  return {

  }
}

const mapDispatchToProps = {
  loginUser
}

LoginContainer.propTypes = {
  routeProps: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
