import { combineReducers } from 'redux'

import AddressReducer from './address_reducer'
import CreditCardReducer from './credit_cards_reducer'
import BookingCalendarReducer from './booking_calendar_reducer'
import ModalReducer from './modal_reducer'
import RegionReducer from './regions_reducer'
import SelectedBookingAddressReducer from './selected_booking_address_reducer'
import SubscriptionReducer from './subscription_reducer'
import UserReducer from './user_reducer'
import AddonsReducer from './addons_reducer'
import BookingsReducer from './bookings_reducer'
import UpsertBookingReducer from './upsert_booking_reducer'
import PasswordResetReducer from './password_reset_reducer'
import AdminDashboardReducer from './admin_dashboard_reducer'
import DiscountsReducer from './discounts_reducer'

const reducers = combineReducers({
  'addons': AddonsReducer || null,
  'addresses': AddressReducer || null,
  'admin_dashboard': AdminDashboardReducer || null,
  'bookings': BookingsReducer || null,
  'credit_cards': CreditCardReducer || null,
  'booking-calendar': BookingCalendarReducer || null,
  'discounts': DiscountsReducer || null,
  'modal': ModalReducer || null,
  'password_reset': PasswordResetReducer || null,
  'selected_booking_address': SelectedBookingAddressReducer || null,
  'subscriptions': SubscriptionReducer || null,
  'upsert-booking': UpsertBookingReducer || null,
  'users': UserReducer || null,
  'regions': RegionReducer || null,
})

export default reducers
