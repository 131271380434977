import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Row, Col } from 'reactstrap';
import { formatTime, wDayLongMonthDayDate } from '../../utils/date_helper'
import BookingCalendarContainer from '../../containers/bookings/BookingCalendarContainer'
import BookingAddonsContainer from '../../containers/bookings/BookingAddonsContainer'
import BookingCheckoutContainer from '../../containers/bookings/BookingCheckoutContainer'
import UserAddressesContainer from '../../containers/UserAddressesContainer'
import UserCreditCardsContainer from '../../containers/UserCreditCardsContainer'
import '../../styles/containers/upsert_booking.scss'

export default class UpsertBooking extends Component {

  render() {
    return (
      <div className='upsert-booking'>
        { this.renderAdminOverride() }
        { this.renderAddressAndTimeSelection() }
      </div>
    )
  }

  renderAdminOverride = () => {
    const { adminCalendarOverrideMode, currentUser, setBookingCalendarOverrideMode } = this.props
    if(currentUser.get('admin')){
      return (
        <div className='admin-calendar-override'>
          <div>
            <label>Admin Override</label>
            <input
              className='admin-calendar-override-input'
              type='checkbox'
              checked={ adminCalendarOverrideMode }
              onClick={ (e) => { setBookingCalendarOverrideMode(e.currentTarget.checked) } }
            />
          </div>
          <span>Show all dates/times regardless of availability</span>
        </div>
      )
    } else {
      return null
    }
  }

  renderAddressAndTimeSelection = () => {
    const { clearBookingDateAndTime, date, time } = this.props
    if(date && time) {
      return (
        <Row>
          <Col md="6" sm="12">{ this.renderAddresses() }</Col>
          <hr className='mobile-selection-divider d-block d-sm-none' />
          <Col md="6" sm="12" className='booking-selection-display'>
            <div className='booking-selection-display-title'>
              Your Cleaning Booking
            </div>
            <div className='booking-selection-display-value'>
              {wDayLongMonthDayDate(date)} @ {formatTime(time)}
            </div>
            <div className='clear-date-selection' onClick={clearBookingDateAndTime}>
              Change Date/Time Selection
            </div>
          </Col>
          <hr className='mobile-selection-divider d-block d-sm-none' />
          <Col md="6" sm="12">{ this.renderCreditCards() }</Col>
          <Col md="6" sm="12">{ this.renderAddons() }</Col>
          <Col xs="12">{ this.renderCheckout() }</Col>
        </Row>
      )
    } else {
      return (
        <React.Fragment>
          { this.renderAddresses() }
          { this.renderBookingCalendar() }
        </React.Fragment>
      )
    }
  }

  renderAddresses = () => {
    const { user, booking } = this.props
    const label = <label>Addresses</label>
    if(booking){
      const address = booking.address
      const street = address.unit ? address.street : "${address.street} #{address.unit}"
      return (
        <div className='address-selection'>
          <label>Address</label>
          <p>
            { address.street }, { address.city } { address.zipCode }
          </p>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <UserAddressesContainer
            label={ label }
            selectMode={ true }
            userId={ user.get('id') }
            type='select'
          />
        </React.Fragment>
      )
    }
  }

  renderBookingCalendar = () => {
    const { user } = this.props
    return (
      <BookingCalendarContainer
        user={ user }
      />
    )
  }

  renderCreditCards = () => {
    const { user } = this.props
    const label = <div className='credit-cards-section-title'>Payment</div>
    return (
      <UserCreditCardsContainer
        label={ label }
        selectMode={ true }
        userId={ user.get('id') }
      />
    )
  }

  renderAddons = () => {
    const { bookingType } = this.props
    return <BookingAddonsContainer bookingType={ bookingType }/>
  }

  renderCheckout = () => {
    const {
      bookingId,
      bookingType,
      user
    } = this.props
    return (
      <BookingCheckoutContainer
        bookingId={ bookingId }
        bookingType={ bookingType }
        user={ user }
      />
    )
  }
}

UpsertBooking.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  booking: PropTypes.object,
  bookingId: PropTypes.number,
  bookingType: PropTypes.string,
  clearBookingDateAndTime: PropTypes.func.isRequired,
  currentUser: ImmutablePropTypes.map.isRequired,
  date: PropTypes.string,
  time: PropTypes.string,
  setBookingCalendarOverrideMode: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
};
