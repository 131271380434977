import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { completeBooking, sendTestReceiptEmail } from '../../actions/bookings_actions'
import { toggleModal } from '../../actions/modal_actions'
import { formatTime, wDayLongMonthDayDate } from '../../utils/date_helper'
import { Alert, Row, Col } from 'reactstrap'
import { getBookingsWindowStart } from '../../reducers/admin_dashboard_reducer'

class CompleteBookingModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      charged_amount: props.booking.total_low_price,
      error: undefined
    }
  }

  render() {
    const { booking, sendTestReceiptEmail } = this.props
    const { charged_amount, error } =this.state
    let quotedPrice = `$${parseInt(booking.total_low_price).toFixed(2)}`
    if(booking.total_high_price){
      quotedPrice += ` $${parseInt(booking.total_high_price).toFixed(2)}`
    }
    const errorWarning = error ? <Alert color="danger">{error}</Alert> : null
    return (
      <React.Fragment>
        <h2>Complete Booking</h2>
        <h6>Booking for {booking.user.first_name} {booking.user.last_name}</h6>
        <h6>{booking.address.bedrooms}bd/{booking.address.bathrooms}ba {booking.address.street}</h6>
        <h6>{wDayLongMonthDayDate(booking.start_date)} @ {formatTime(booking.start_time)}</h6>
        Customer was quoted <b>{ quotedPrice }</b>
        <br/>
        Discount amount: <b>${parseInt(booking.discount_amount).toFixed(2)}</b>
        <br/>
        Addons amount: <b>${parseInt(booking.addon_price).toFixed(2)}</b>
        <br/>
        <br/>
        <div className='form-field'>
          <b>How much do you want to bill the customer?</b>
          <input
            name="charged_amount"
            type="text"
            class="form-control"
            value={charged_amount}
            onChange={ this.changeChargeAmount }
          />
        </div>
        Card ending in {booking.credit_card} will be charged
        <br/>
        <button className='btn btn-primary' onClick={ this.completeBooking }>
          Complete Booking
        </button>
        <button className='btn btn-secondary' onClick={ () => sendTestReceiptEmail(booking.id) }>
          Send Test Receipt Email
        </button>
        <br/>
        { errorWarning }
      </React.Fragment>
    )
  }

  completeBooking = () => {
    const { charged_amount } = this.state
    const { booking, completeBooking, toggleModal, windowStart } = this.props
    const onSuccess = () => {
      toggleModal()
    }
    const onFailure = (e) => {
      this.setState({ error: e.message })
    }
    completeBooking({
      bookingId: booking.id,
      charged_amount,
      onFailure,
      onSuccess,
      userId: booking.user.id,
      windowStart
    })
  }

  changeChargeAmount = (e) => {
    let val = parseInt(e.currentTarget.value)
    if(isNaN(val)){
      val = 0
    }
    this.setState({ charged_amount: val })
  }
}

const mapStateToProps = (state, props) => {
  return {
    windowStart: getBookingsWindowStart(state)
  }
}

const mapDispatchToProps = {
  toggleModal,
  completeBooking,
  sendTestReceiptEmail
}

CompleteBookingModalContainer.propTypes = {
  bookingId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteBookingModalContainer)
