import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getCurrentUser } from '../../reducers/user_reducer'
import { logoutCurrentUser } from '../../actions/user_actions'
import { navigate } from '@reach/router'
import logo from '../../assets/images/logo.jpg'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

class HeaderContainer extends Component {
  state = {
    isOpen: false
  }

  toggleHeader = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Navbar style={{backgroundColor: '#00B287', color: 'white'}} dark expand="md">
        <NavbarBrand href="https://gowellnest.com"><img src={logo}/></NavbarBrand>
        <NavbarToggler onClick={this.toggleHeader} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            { this.renderNavOptions() }
          </Nav>
          { this.renderAuthenticationButton() }
        </Collapse>
      </Navbar>
    )
  }

  renderNavOptions = () => {
    const { currentUser } = this.props

    if(!currentUser){
      return ''
    } else if( currentUser.get('admin') ) {
      return (
        <NavItem>
          <NavLink href="/admin_dashboard">Admin Dashboard</NavLink>
        </NavItem>
      )
    } else {
      return (
        <NavItem>
          <NavLink href={`/users/${currentUser.get('id')}`}>Manage Bookings</NavLink>
        </NavItem>
      )
    }
  }

  renderAuthenticationButton = () => {
    const { currentUser } = this.props
    if(currentUser){
      return (
        <button className='btn btn-secondary' onClick={ this.logoutUser }>Logout</button>
      )
    } else {
      return (
        <button className='btn btn-secondary' onClick={ () => navigate('/login') }>Login</button>
      )
    }
  }

  logoutUser = () => {
    const { logoutCurrentUser } = this.props
    navigate('/login').then(() => {
      logoutCurrentUser()
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: getCurrentUser(state)
  }
}

const mapDispatchToProps = {
  logoutCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
