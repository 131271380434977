import Immutable from 'immutable'
import { createReducer, shouldFetchValue } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_RECIEVED,
  CREDIT_CARDS_FETCH_REQUESTED,
  CREDIT_CARDS_FETCH_RECIEVED,
  CREDIT_CARDS_FETCH_FAILED,
  CREDIT_CARD_SUBMISSION_REQUESTED,
  CREDIT_CARD_SUBMISSION_RECIEVED,
  CREDIT_CARD_SUBMISSION_FAILED,
  USER_DASHBOARD_FETCH_RECIEVED,
  CREDIT_CARD_DELETE_RECIEVED
} from '../utils/constants'

export const stateKey = 'credit_cards'

let initialState = Immutable.Map({
  isFetching: false,
  creditCards: Immutable.Map()
})

const CreditCardReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [CREDIT_CARDS_FETCH_REQUESTED]: (state) => state.merge({isFetching: true}),
    [CREDIT_CARDS_FETCH_RECIEVED]: (state, { payload }) => {
      state = state.merge({ isFetching: false, retryCount: 0 })
      const newVal = Immutable.Map({ [
        payload.userId]: Immutable.List(payload.results)
      })
      return state.mergeIn(['creditCards'], newVal)
    },
    [CREDIT_CARDS_FETCH_FAILED]: (state) => {
      return state.merge({
        isFetching: true,
        retryCount: state.get('retryCount') + 1
      })
    },
    [CREDIT_CARD_SUBMISSION_REQUESTED]: (state) => {
      return state.merge({
        isFetching: true,
      })
    },
    [CREDIT_CARD_SUBMISSION_RECIEVED]: (state, payload) => {
      const creditCardPath = ['creditCards', payload.userId]
      const newArray = state.getIn(creditCardPath, Immutable.List()).push(payload.creditCard)
      return state.merge({
        isFetching: false,
      }).setIn(
        creditCardPath,
        newArray
      )
    },
    [CREDIT_CARD_SUBMISSION_FAILED]: (state) => {
      return state.merge({
        isFetching: false,
      })
    },
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.setIn(['creditCards', payload.userId], Immutable.List(payload.results.creditCards))
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload, userId }) => {
      return state.setIn(['creditCards', userId], Immutable.List(payload.creditCards))
    },
    [CREDIT_CARD_DELETE_RECIEVED]: (state, { creditCardId, userId }) => {
      let cards = state.getIn(['creditCards', userId])
      const deletedIndex = cards.findIndex((card) => card.id == creditCardId)
      cards = cards.delete(deletedIndex)
      return state.setIn(['creditCards', userId], cards)
    }
  });
}

export const getUserCreditCards = (globalState, userId) => {
  return getCreditCardState(globalState).getIn(['creditCards', userId])
}
export const getCreditCardState = (globalState) => globalState[stateKey];
export const shouldFetchCreditCards = (globalState, userId) => {
  return shouldFetchValue(getCreditCardState(globalState), ['creditCards', userId], 3)
}
export const isFetching = (globalState) => getCreditCardState(globalState).get('isFetching')

export default CreditCardReducer
