import Immutable from 'immutable'
import { createReducer, shouldFetchValue } from '../utils/reducers'
import {
  ADDRESSES_FETCH_REQUESTED,
  ADDRESSES_FETCH_RECIEVED,
  ADDRESSES_FETCH_FAILED,
  ADDRESSES_SUBMISSION_REQUESTED,
  ADDRESSES_SUBMISSION_RECIEVED,
  ADDRESSES_SUBMISSION_FAILED,
  BOOKING_CALENDAR_FETCH_RECIEVED,
  USER_DASHBOARD_FETCH_RECIEVED,
  DELETE_ADDRESS_RECIEVED
} from '../utils/constants'

export const stateKey = 'addresses'

let initialState = Immutable.Map({
  addresses: Immutable.Map(),
  isFetching: false,
  retryCount: 0
})

const AddressReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [ADDRESSES_FETCH_REQUESTED]: (state) => state.merge({isFetching: true}),
    [ADDRESSES_FETCH_RECIEVED]: (state, { payload }) => {
      state = state.merge({ isFetching: false, retryCount: 0 })
      const newVal = Immutable.Map({ [
        payload.userId]: Immutable.List(payload.results)
      })
      return state.mergeIn(['addresses'], newVal)
    },
    [ADDRESSES_FETCH_FAILED]: (state) => {
      return state.merge({
        isFetching: true,
        retryCount: state.get('retryCount') + 1
      })
    },
    [ADDRESSES_SUBMISSION_REQUESTED]: (state) => {
      return state.merge({
        isFetching: true,
      })
    },
    [ADDRESSES_SUBMISSION_RECIEVED]: (state, payload) => {
      const addressPath = ['addresses', payload.userId]
      return state.merge({
        isFetching: false,
      }).setIn(addressPath, state.getIn(addressPath).push(payload.address))
    },
    [ADDRESSES_SUBMISSION_FAILED]: (state) => {
      return state.merge({
        isFetching: false,
      })
    },
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {

      let addresses = state.get('addresses')
      addresses = addresses.set(payload.userId, Immutable.List(payload.results.addresses))
      return state.set('addresses', addresses)
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload, userId }) => {
      return state.setIn(['addresses', userId], Immutable.List(payload.addresses))
    },
    [DELETE_ADDRESS_RECIEVED]: (state, { userId, addressId }) => {
      return state.deleteIn([
        'addresses',
        userId,
        state.getIn(['addresses', userId]).findIndex((a) => a.id == addressId)
      ])
    }
  });
}

export const getUserAddresses = (globalState, userId) => {
  return getAddressState(globalState).getIn(['addresses', userId], Immutable.List())
}

export const getAddressState = (globalState) => globalState[stateKey];

export const isFetching = (globalState) => getAddressState(globalState).get('isFetching');

export const shouldFetchAddresses = (globalState, userId) => {
  return shouldFetchValue(getAddressState(globalState), ['addresses', userId.toString()], 3)
}

export default AddressReducer
