import {
  FEEDBACK_SUBMISSION_REQUESTED,
  FEEDBACK_SUBMISSION_RECIEVED,
  FEEDBACK_SUBMISSION_FAILED,
} from '../utils/constants'

import { fetchAction } from '../utils/actions'

export function submitFeedback({bookingId, feedback, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/bookings/${bookingId}/feedback`,
      data: feedback
    },
    requestAction: () => { return { type: FEEDBACK_SUBMISSION_REQUESTED } },
    receivedAction: (results) => { return { type: FEEDBACK_SUBMISSION_RECIEVED, payload: { results   } } },
    failureAction: (error) => { return { type: FEEDBACK_SUBMISSION_FAILED, payload: { error } } },
    onSuccess,
    onFailure
  });
}
