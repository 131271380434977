import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';
import UserBookingsContainer from '../../containers/user_dashboard/UserBookingsContainer'
import SubscriptionContainer from '../../containers/user_dashboard/SubscriptionContainer'
import UserAddressesContainer from '../../containers/UserAddressesContainer'
import UserCreditCardsContainer from '../../containers/UserCreditCardsContainer'
import { Row, Col } from 'reactstrap';
import pencil from '../../assets/icons/pencil.svg'

export default class UserDashboard extends Component {
  render() {
    const { addresses, bookings, creditCards, user } = this.props
    if(bookings){
      return (
        <React.Fragment>
          <div className='welcome-text'>
            { this.renderWelcome() }
          </div>
          <UserBookingsContainer user={ user }/>
          <Row>
            <Col md="6" sm="12">
              <h5>Subscription(s)</h5>
              { <SubscriptionContainer user={user}/> }
            </Col>
            <Col md="6" sm="12">
              { this.renderAddresses() }
            </Col>
            <Col md="6" sm="12">
              <div className='user-dashboard-credit-cards'>
                <h5>Credit Cards</h5>
                <UserCreditCardsContainer
                  creditCards={creditCards}
                  userId={ user.get('id') }
                  type='table'
                />
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className='user-dashboard-referrals'>
                <h5>Referrals</h5>
                { this.renderReferrals() }
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else {
      return ''
    }
  }

  renderAddresses = () => {
    const { user } = this.props
    const label = <h5>Addresses</h5>
    return (
      <div className='user-dashboard-addresses'>
        <UserAddressesContainer
          label={ label }
          placeholder='View Addresses'
          selectMode={ true }
          userId={ user.get('id') }
          type='table'
        />
      </div>
    )
  }

  renderWelcome = () => {
    const { user, currentUser } = this.props
    let text;
    if(user.get('id') === currentUser.get('id')) {
      text = `Welcome, ${user.get('firstName')}`
    } else {
      text = `${user.get('firstName')} ${user.get('lastName')}`
    }

    if(currentUser.get('admin')){
      return (
        <React.Fragment>
          {text}
          <img
            title='Edit User'
            className='edit-user-action'
            src={pencil}
            onClick={ this.openUserUpdaterModal }
          />
        </React.Fragment>
        )
    } else {
      return text;
    }
  }

  openUserUpdaterModal = () => {
    const { toggleModal, user } = this.props
    toggleModal('UserUpdaterModalContainer', { user })
  }

  renderReferrals = () => {
    const { user } = this.props
    return (
      <Row>
        <Col sm="12">
          <div className='user-referral-code-title'>
            Your referral code is <b>{ user.get('referralCode') }</b>
          </div>
          <div className='user-referral-code-sub-title'>
            When your friends use this code on sign up, you will earn $50 and
            they will recieve 10% off their first cleaning (only applicable
              for subscription bookings)
          </div>
        </Col>
        <Col sm="6">
          Earned Referral Credit:
          <br/>
          <b>${ user.get('referralAmountEarned') }0</b>
        </Col>
        <Col sm="6">
          Redeemed Referral Credit:
          <br/>
          <b>${ user.get('referralAmountRedeemed') }0</b>
        </Col>
      </Row>
    )
  }
}

UserDashboard.propTypes = {
  addresses: ImmutablePropTypes.list,
  creditCards: ImmutablePropTypes.list,
  bookings: ImmutablePropTypes.list,
  currentUser: ImmutablePropTypes.map,
  user: ImmutablePropTypes.map.isRequired,
  toggleModal: PropTypes.func.isRequired
};
