import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_RECIEVED,
  BOOKING_FETCH_RECIEVED,
  BOOKING_SUBMISSION_RECIEVED,
  BOOKING_UPDATE_RECIEVED,
  CLEAR_BOOKING_DATE_AND_TIME,
  UPDATE_SELECTED_ADDON_IDS,
  UPDATE_SELECTED_ADDRESS_ID,
  UPDATE_SELECTED_CREDIT_CARD_ID,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_TIME,
} from '../utils/constants'

export const stateKey = 'upsert-booking'

let initialState = Immutable.Map({
  selectedAddonIds: Immutable.List(),
  selectedAddressId: undefined,
  selectedCreditCardId: undefined,
  selectedDate: undefined,
  selectedTime: undefined,
  discount: undefined,
})

const UpsertBookingReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      const discount = payload.results.discount
      if(discount.valid){
        state = state.set('discount', discount.code)
      }

      return state.set('selectedAddressId', payload.results.selectedAddressId)
    },
    [CLEAR_BOOKING_DATE_AND_TIME]: (state, { value} ) => {
      return state.set('selectedDate', undefined)
        .set('selectedTime', undefined)
    },
    [UPDATE_SELECTED_ADDON_IDS]: (state, { value} ) => {
      return state.set('selectedAddonIds', Immutable.List(value))
    },
    [UPDATE_SELECTED_ADDRESS_ID]: (state, { value }) => {
      console.log(value)
      return state.set('selectedAddressId', value)
    },
    [UPDATE_SELECTED_CREDIT_CARD_ID]: (state, { value }) => {
      return state.set('selectedCreditCardId', value)
    },
    [UPDATE_SELECTED_DATE]: (state, { value }) => {
      return state.set('selectedDate', value)
    },
    [UPDATE_SELECTED_TIME]: (state, { value }) => {
      return state.set('selectedTime', value)
    },
    [BOOKING_SUBMISSION_RECIEVED]: (state) => {
      return initialState
    },
    [BOOKING_UPDATE_RECIEVED]: (state) => {
      return initialState
    },
    [BOOKING_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('discount', payload.discountCode)
    },
  });
}

export const getSelectedAddonIds = (globalState) => {
  return getUpsertBookingState(globalState).get('selectedAddonIds')
}
export const getSelectedAddressId = (globalState) => {
  return getUpsertBookingState(globalState).get('selectedAddressId')
}
export const getSelectedCreditCardId = (globalState) => {
  return getUpsertBookingState(globalState).get('selectedCreditCardId')
}
export const getSelectedDate = (globalState) => {
  return getUpsertBookingState(globalState).get('selectedDate')
}
export const getSelectedTime = (globalState) => {
  return getUpsertBookingState(globalState).get('selectedTime')
}

export const getDiscountCode = (globalState) => {
  return getUpsertBookingState(globalState).get('discount')
}

export const getUpsertBookingState = (globalState) => globalState[stateKey];

export default UpsertBookingReducer
