import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { required, isNumber } from '../../utils/validations'
import BasicForm from '../../components/forms/BasicForm'
import { submitUserAddress } from '../../actions/address_actions'
import { toggleModal } from '../../actions/modal_actions'
import { isFetching } from '../../reducers/address_reducer'
import { Alert } from 'reactstrap'

import '../../styles/new_address_modal_container.scss'

class NewAddressModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      submissionError: undefined
    }
  }

  fields = [
    {
      label: 'Street',
      name: 'street',
      placeholder: '1600 Pennsylvania Avenue',
      type: 'text',
      validations: [required]
    },
    {
      label: 'Unit',
      name: 'unit',
      placeholder: 'Apt 220',
      type: 'text',
      validations: []
    },
    {
      label: 'City',
      name: 'city',
      placeholder: 'Falls Church',
      type: 'text',
      validations: [required]
    },
    {
      label: 'Zip Code',
      name: 'zipCode',
      placeholder: '22040',
      type: 'text',
      validations: [required, isNumber]
    },
    {
      label: 'Bedrooms',
      name: 'bedrooms',
      placeholder: 'As Per MLS',
      type: 'text',
      validations: [required, isNumber]
    },
    {
      label: 'Bathrooms',
      name: 'bathrooms',
      placeholder: 'As Per MLS',
      type: 'text',
      validations: [required, isNumber]
    },
    {
      label: 'How Should We Enter?',
      name: 'entryInformation',
      placeholder: 'I will let you in, the doorman will give you access, or the key will be under the mat',
      type: 'textarea',
      validations: []
    },
    {
      label: 'Any Special Instructions?',
      name: 'specialInstructions',
      placeholder: 'Lockbox codes, trouble areas, areas to not clean, areas to focus on, anything specific we should know before cleaning your home!',
      type: 'textarea',
      validations: []
    },
  ]

  render() {
    const { addressIsSaving } = this.props
    const { submissionError } = this.state
    const alert = submissionError ? <Alert color="danger">{ submissionError }</Alert> : ''
    return (
      <div className='new-address-modal-container'>
        <h4>Add New Address</h4>
        <BasicForm
          fields={ this.fields }
          onSubmit={ this.onSubmit }
          submitDisabled={ this.submitDisabled() }
          isSaving={ addressIsSaving }
        />
        <div class='padding-top-10'>
          { alert }
        </div>
      </div>
    )
  }

  submitDisabled = () => {
    return false
  }

  onSubmit = (address) => {
    const { submitUserAddress, toggleModal, userId } = this.props
    submitUserAddress(
      address,
      userId,
      () => { toggleModal() },
      (error) => {
        this.setState({'submissionError': error.message || 'Uh Oh! Something went wrong and your address could not be saved. Please try again.'})
      }
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    addressIsSaving: isFetching(state)
  }
}

const mapDispatchToProps = {
  submitUserAddress,
  toggleModal
}

NewAddressModalContainer.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAddressModalContainer)
