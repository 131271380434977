import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap'
import { sendRecoveryCode, validateRecoveryCode, updatePassword } from '../../actions/authentication_actions'
import { navigate } from '@reach/router'
import { isFetching } from '../../reducers/password_reset_reducer'
import SendRecoveryCode from '../../components/authentication/SendRecoveryCode'
import ValidateRecoveryCode from '../../components/authentication/ValidateRecoveryCode'
import UpdatePassword from '../../components/authentication/UpdatePassword'

class ForgottenPasswordContainer extends Component {
  state = {
    code: undefined,
    email: undefined,
    error: undefined,
    stage: 'sendCode',
    success: undefined
  }

  render() {
    const { error, success, stage } = this.state

    let errorMessage = error ? <Alert color="danger">{ error }</Alert> : null
    let successMessage = success ? <Alert color="success">{ success }</Alert> : null

    let component
    if(stage === 'sendCode') {
      component = <SendRecoveryCode onSubmit={ this.sendCode } />
    } else if(stage == 'validateCode') {
      component = <ValidateRecoveryCode onSubmit={ this.validateCode } />
    } else if(stage == 'updatePassword'){
      component = <UpdatePassword onSubmit={ this.updatePassword } />
    }

    return (
      <div className='auth-container'>
        <h2>Recover Password</h2>
        { component }
        { errorMessage }
        { successMessage }
      </div>
    )
  }

  sendCode = (formValues) => {
    const { sendRecoveryCode } = this.props
    const { email } = formValues

    this.setState({ error: undefined })

    sendRecoveryCode({
      email: email,
      onSuccess: () => this.setState({
        email: email,
        stage: 'validateCode',
        success: "A recovery code has been sent to your email. Please check your inbox and enter it here."
      }),
      onFailure: () => this.setState({ error: 'Something Went Wrong. Please Try again' })
    })
  }

  validateCode = (formValues) => {
    const { email } = this.state
    const { validateRecoveryCode } = this.props
    const { code } = formValues

    this.setState({ error: undefined, success: undefined })

    validateRecoveryCode({
      email,
      code,
      onSuccess: () => {
        this.setState({
          code: formValues.code,
          stage: 'updatePassword',
        })
      },
      onFailure: (error) => {
        if(!isNaN(error.code)) {
          if(error.code == 0){
            this.setState({ error: 'Your recovery code has expired. Please try again.', stage: 'sendCode' })
          } else {
            this.setState({ error: error.message })
          }
        } else {
          this.setState({ error: 'Something Went Wrong. Please Try again' })
        }
      }
    })
  }

  updatePassword = (formValues) => {
    const { email, code } = this.state
    const { updatePassword } = this.props
    const { password } = formValues

    this.setState({ error: undefined, success: undefined })

    updatePassword({
      email,
      code,
      password,
      onSuccess: () => navigate('/login'),
      onFailure: () => this.setState({ error: 'Something Went Wrong. Please Try again' })
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    isFetching: isFetching(state)
  }
}

const mapDispatchToProps = {
  sendRecoveryCode,
  validateRecoveryCode,
  updatePassword
}

ForgottenPasswordContainer.propTypes = {
  routeProps: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordContainer)
