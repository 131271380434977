import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';
import Select from 'react-select-v2'

export default class CreditCardList extends Component {

  render() {
    const { label } = this.props
    return (
      <div className='credit-cards-container'>
        { label }
        { this.renderCards() }
      </div>
    )
  }

  renderCards = () => {
    const { addNewCard, creditCards, selectedCreditCard, setCreditCard } = this.props
    const options = this.getOptions()
    const selectedOption = options.filter((option) => option.value === selectedCreditCard).get(0)
    if(creditCards && creditCards.size > 0) {
      return (
        <React.Fragment>
          <Select
            className='credit-card-selection-dropdown'
            options={ options }
            value={ selectedOption }
            onChange={ setCreditCard }
          />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className='btn btn-primary first-credit-card-entry' onClick={ addNewCard }>
            Enter Credit Card to Continue
          </div>
        </React.Fragment>
      )
    }
  }

  getOptions = () => {
    const { creditCards } = this.props
    return creditCards.map((creditCard) => {
      const label = `•••• ${creditCard.lastFour} - ${creditCard.expDate}`
      return { value: creditCard.id, label }
    })
  }
}

CreditCardList.propTypes = {
  addNewCard: PropTypes.func.isRequired,
  creditCards: ImmutablePropTypes.list.isRequired,
  label: PropTypes.element,
  selectedCreditCard: PropTypes.number,
  setCreditCard: PropTypes.func.isRequired
};
