import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  BOOKING_CALENDAR_FETCH_RECIEVED,
  USER_DASHBOARD_FETCH_RECIEVED,
  FETCH_ADMIN_BOOKINGS_RECIEVED
} from '../utils/constants'

export const stateKey = 'regions'

let initialState = Immutable.Map({
    regions: Immutable.List()
})

const RegionReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [BOOKING_CALENDAR_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('regions', Immutable.fromJS(payload.results.regions))
    },
    [USER_DASHBOARD_FETCH_RECIEVED]: (state, { payload }) => {
      return state.set('regions', Immutable.fromJS(payload.regions))
    },
    [FETCH_ADMIN_BOOKINGS_RECIEVED]: (state, { results }) => {
      return state.set('regions', Immutable.fromJS(results.regions))
    },
  });
}

export const getRegions = (globalState) => {
  return getRegionState(globalState).get('regions')
}

export const getRegionByZipcode = (globalState, zipCode) => {
  return getRegionState(globalState).get('regions').filter((region)=> {
    return region.get('zipCodes').includes(zipCode)
  }).get(0)
}

export const getRegionState = (globalState) => globalState[stateKey];
export default RegionReducer
