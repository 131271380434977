import Immutable from 'immutable'
import { createReducer } from '../utils/reducers'
import {
  TOGGLE_MODAL
} from '../utils/constants'

export const stateKey = 'modal'

let initialState = Immutable.Map({
  isOpen: false,
  component: undefined,
  props: {}
})

const ModalReducer = (state = initialState, action) => {
  return createReducer(state, action, {
    [TOGGLE_MODAL]: (state, { props, component }) => {
      if (state.get('isOpen')) {
        return state.merge({ isOpen: false, component: false }).set('props', {})
      } else {
        return state.merge({ isOpen: true, component, props })
      }
    }
  });
}

export const isModalOpen = (globalState) => {
  return getModalState(globalState).get('isOpen')
}

export const getModalComponent = (globalState) => {
  return getModalState(globalState).get('component')
}

export const getModalProps = (globalState) => {
  return getModalState(globalState).get('props')
}

export const getModalState = (globalState) => globalState[stateKey];
export default ModalReducer
