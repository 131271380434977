import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { fetchBookingCalendar, setBookingCalendarOverrideMode } from '../../actions/booking_calendar_actions'
import { fetchUser } from '../../actions/user_actions'
import { fetchBooking } from '../../actions/bookings_actions'
import { clearBookingDateAndTime } from '../../actions/upsert_booking_actions'
import { getCurrentUser, getUser } from '../../reducers/user_reducer'
import { getBookingCalendar, getCalendarOverrideMode } from '../../reducers/booking_calendar_reducer'
import { getBooking } from '../../reducers/bookings_reducer'
import { getAddons } from '../../reducers/addons_reducer'
import {
  getSelectedAddressId,
  getSelectedAddons,
  getSelectedDate,
  getSelectedTime
} from '../../reducers/upsert_booking_reducer'
import {
  updateSelectedAddonIds,
  updateSelectedAddressId,
  updateSelectedCreditCardId,
  updateSelectedDate,
  updateSelectedTime
} from '../../actions/upsert_booking_actions'
import { hoursTillBooking } from '../../utils/date_helper'
import { redirectTo, navigate } from "@reach/router"

import UpsertBooking from '../../components/bookings/UpsertBooking'
import { getState } from '../../utils/store'

class UpsertBookingContainer extends Component {

  componentDidMount(){
    const {
      clearBookingDateAndTime,
      booking,
      bookingId,
      fetchBooking,
      fetchBookingCalendar,
      fetchUser,
      user,
      userId,
      routeProps
    } = this.props
    if(!routeProps.bookingId && !routeProps.type) {
      redirectTo('/')
    }

    const discount = routeProps.discount_code

    // If url is scope to booking and booking is already in redux
    if(booking) {
      clearBookingDateAndTime()
      this.redirectIfNotEditable(booking)
      this.handleBookingScoped(booking, discount)
    // If url is scope to booking and booking is not in redux
    } else if (routeProps.bookingId) {
      clearBookingDateAndTime()
      fetchBooking(routeProps.bookingId, (booking) => {
        this.handleBookingScoped(booking, discount)
        this.redirectIfNotEditable(booking)
      })
    // If scoped user is in redux, or user is current user
    } else if(user) {
      fetchBookingCalendar(undefined, user.get('id'), discount)
    // If url is scoped to user and user is not in redux
    } else {
      clearBookingDateAndTime()
      fetchUser(routeProps.userId, (user) => {
        fetchBookingCalendar(undefined, user.id, discount)
      })
    }
  }

  handleBookingScoped = (booking, discount) => {
    const { fetchBookingCalendar } = this.props
    fetchBookingCalendar(booking.address.id, booking.user.id, discount, (results) => {
      this.setDefaultValues(
        booking.address.id,
        results.creditCards.map((card) => card.id).includes(booking.creditCard.id) ? booking.creditCard.id : null,
        booking.addons.map((a) => a.id),
        booking.startDate,
        booking.startTime
      )
    })
  }

  componentDidUpdate(){
    const { fetchBookingCalendar, selectedAddressId, user } = this.props
    if(selectedAddressId && user) {
      fetchBookingCalendar(selectedAddressId, user.get('id'))
    }
  }

  redirectIfNotEditable = (booking) => {
    const { currentUser } = this.props
    if(booking){
      if(!currentUser.get('admin')) {
        const hoursTill = hoursTillBooking(booking)
        if(hoursTill < 36) {
          navigate(`/users/${booking.user.id}`)
        }
      }
    }
  }

  render() {
    const {
      adminCalendarOverrideMode,
      booking,
      bookingCalendar,
      clearBookingDateAndTime,
      currentUser,
      date,
      selectedAddons,
      selectedAddressId,
      selectedCreditCard,
      time,
      bookingType,
      setBookingCalendarOverrideMode,
      user,
      routeProps
    } = this.props
    if(bookingCalendar) {
      return (
        <UpsertBooking
          adminCalendarOverrideMode={ adminCalendarOverrideMode }
          booking={ booking }
          bookingId={ routeProps.bookingId }
          bookingType={ bookingType }
          currentUser={ currentUser }
          clearBookingDateAndTime={ clearBookingDateAndTime }
          date={ date }
          time={ time }
          setBookingCalendarOverrideMode={ setBookingCalendarOverrideMode }
          user={user}
        />
      )
    }
    return ''
  }

  setDefaultValues(addressId, creditCardId, addonIds, date, time) {
    const {
      updateSelectedAddonIds,
      updateSelectedAddressId,
      updateSelectedCreditCardId,
      updateSelectedDate,
      updateSelectedTime
    } = this.props

    updateSelectedAddressId({value: addressId})
    updateSelectedCreditCardId({value: creditCardId})
    if(addonIds && addonIds.length) {
      updateSelectedAddonIds(addonIds)
    }

    if(date && time) {
      updateSelectedDate(date)
      updateSelectedTime(time)
    }
  }
}

const mapStateToProps = (state, props) => {
  const { routeProps } = props

  const bookingId = routeProps.bookingId
  const userId = routeProps.userId
  let booking
  if(bookingId) {
    booking = getBooking(state, bookingId)
  }

  const selectedAddressId = getSelectedAddressId(state)
  const currentUser = getCurrentUser(state)
  let user, bookingType;
  if(booking){
    user = getUser(state, booking.user.id)
    bookingType = booking.type
  } else {
    bookingType = routeProps.type
    user = userId ? getUser(state, userId) : currentUser
  }

  return {
    adminCalendarOverrideMode: getCalendarOverrideMode(state),
    booking,
    bookingCalendar: getBookingCalendar(state, selectedAddressId),
    bookingType,
    currentUser,
    user,
    selectedAddressId,
    date: getSelectedDate(state),
    time: getSelectedTime(state)
  }
}

const mapDispatchToProps = {
  clearBookingDateAndTime,
  fetchBooking,
  fetchBookingCalendar,
  fetchUser,
  setBookingCalendarOverrideMode,
  updateSelectedAddonIds,
  updateSelectedAddressId,
  updateSelectedCreditCardId,
  updateSelectedTime,
  updateSelectedDate
}

UpsertBookingContainer.propTypes = {
  type:  PropTypes.string,
  userId:  PropTypes.number,
  bookingId:  PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertBookingContainer)
