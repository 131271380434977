import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types';

export default class CreditCardTable extends Component {
  render() {
    return (
      <div className='credit-card-selection'>
        <table class="table">
          { this.renderAddresses() }
        </table>
      </div>
    );
  }

  renderAddresses = () => {
    const { creditCards, deleteCreditCard } = this.props

    return creditCards.map ((card) => {
      return (
        <tr>
          <td>
           XXXX-XXXX-{card.lastFour}
          </td>
          <td>
            <div
              onClick={ () => deleteCreditCard(card.id) }
              class='btn btn-primary btn-sm action-button'>
              Delete
            </div>
          </td>
        </tr>
      )
    })
  }
}

CreditCardTable.propTypes = {
  creditCards: ImmutablePropTypes.list.isRequired,
  deleteCreditCard: PropTypes.func
};
