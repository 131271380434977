import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { getUser, getCurrentUser } from '../../reducers/user_reducer'
import { getUserBookings } from '../../reducers/bookings_reducer'
import { getUserAddresses } from '../../reducers/address_reducer'
import { getUserCreditCards } from '../../reducers/credit_cards_reducer'
import { fetchUserDashboard } from '../../actions/user_dashboard_actions'
import { toggleModal } from '../../actions/modal_actions'
import UserDashboard from '../../components/user_dashboard/UserDashboard'
import '../../styles/user_dashboard.scss'

class UserDashboardContainer extends Component {
  constructor(props){
    super()

    props.fetchUserDashboard(parseInt(props.routeProps.userId))
  }

  render() {
    const { addresses, bookings, creditCards, currentUser, user, toggleModal } = this.props
    return (
      <div className='user-dashboard'>
        <UserDashboard
          addresses={ addresses }
          bookings={ bookings }
          creditCards={ creditCards }
          currentUser={ currentUser }
          user={ user }
          toggleModal={ toggleModal }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const userId = parseInt(props.routeProps.userId)
  return {
    addresses: getUserAddresses(state, userId),
    bookings: getUserBookings(state, userId),
    creditCards: getUserCreditCards(state, userId),
    currentUser: getCurrentUser(state),
    user: getUser(state, userId),
  }
}

const mapDispatchToProps = {
  fetchUserDashboard,
  toggleModal
}

UserDashboardContainer.propTypes = {
  userId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardContainer)
