import { getState } from "./store"
import { getUserToken } from '../reducers/user_reducer'
import { navigate } from '@reach/router'
import axios, * as others from 'axios';

export function requestAPI({method, url, data, qs, success, failure}) {
  const keys = qs && Object.keys(qs)
  let queryString;
  if(keys && keys.length){
    queryString = '?'
    Object.keys(qs).forEach( (key, index) => {
      queryString += `${key}=${qs[key]}`

      if(index < keys.length -1){
        queryString += '&'
      }
    })
  }

  const params = {
    method,
    url: `${getURLPrefix()}${url}${queryString || ''}`,
    headers: {
      'Content-type': 'application/json',
      'Authorization': getUserToken(getState())
    }
  }

  if(data){
    params.data = JSON.stringify(data);
  }

  axios(params)
  .then(function (response) {
    // Redirect to login
    if(response && response.status === 401){
      navigate(`/login?redirectPath=${window.location.pathname+window.location.search}`)
    }

    const body = response.data

    if(body && body !== 'null') {
      if(body.error){
        failure(body.error)
      } else {
        success(body)
      }
    }
  })
  .catch(function (error) {
    const message = error?.response?.data?.error
    failure({ message: message, status: error?.response?.status })
  })
}

export function getURLPrefix(){
  const currentUrl = window.location.href
  if(!currentUrl || currentUrl.indexOf('localhost') > -1){
    return 'http://localhost:5000'
  } else {
    return 'https://wellnest-backend.herokuapp.com'
  }
}
