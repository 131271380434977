import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminDashboardDailyBookingsContainer from '../../containers/admin_dashboard/AdminDashboardDailyBookingsContainer'
import { orderBy } from 'lodash'

export default class AdminDashboardWeeklyBookings extends Component {

  render() {
    const { openAllDays, weeklyBookings } = this.props
    return Object.keys(weeklyBookings).map((date) => {
      const dailyBookings = orderBy(weeklyBookings[date], 'start_time')
      return <AdminDashboardDailyBookingsContainer
        date={date}
        dailyBookings={ dailyBookings }
        openAllDays={openAllDays}
      />
    })
  }
}

AdminDashboardWeeklyBookings.propTypes = {
  openAllDays: PropTypes.bool,
  weeklyBookings: PropTypes.object,
};
