import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatCalendarDay } from '../../utils/date_helper'

export default class BookingCalendarDay extends Component {

  render() {
    const {
      adminCalendarOverrideMode,
      date,
      month,
      timesCount,
      selectedDate,
      setDate
    } = this.props

    let activeDate
    if(adminCalendarOverrideMode){
      activeDate = true
    } else {
      activeDate = timesCount > 0
    }
    const dynamicAvailableClass = activeDate ? 'available' : 'not-available'
    const dynamicSelectedClass = selectedDate === date ? 'selected' : ''
    const onClick =  activeDate ? () => setDate(date, month) : () => {}
    if(date){
      return (
        <div
          className={`booking-calendar-day bookable-date ${dynamicAvailableClass} ${dynamicSelectedClass}`}
          onClick={ () => onClick() }
        >
          { this.renderPreviewText() }
          { this.renderHoverText() }
        </div>
      )
    } else {
      return <div className='booking-calendar-day'/>
    }
  }

  renderPreviewText = () => {
    const { date } = this.props
    return (
      <div className='preview-text'>
        <div className='text-title'>{ formatCalendarDay(date) }</div>
        { this.renderPreviewSubtext() }
      </div>
    )
  }

  renderHoverText = () => {
    const { timesCount } = this.props
    return (
      <div className='hover-text'>
        <div className='text-title'>{ timesCount }</div>
        <div className='hover-text-subtext'>Slots</div>
      </div>
    )
  }

  renderPreviewSubtext = () => {
    const { timesCount } = this.props
    return timesCount > 0 ? <div className='text-subtext'>.</div> : ""
  }
}

BookingCalendarDay.propTypes = {
  adminCalendarOverrideMode: PropTypes.bool,
  date: PropTypes.string,
  month: PropTypes.string,
  setDate: PropTypes.func,
  timesCount: PropTypes.number,
  selectedDate: PropTypes.string,
};
