import React from 'react';
import './App.scss';
import { Router, Redirect } from "@reach/router"
import { Container } from 'reactstrap';
import ModalContainer from './containers/modals/ModalContainer'
import { extractQueryParams, extractIds } from './utils/route_helper'
import { getState } from './utils/store'
import { getCurrentUser } from './reducers/user_reducer'
import './styles/navigation.scss'
import './styles/global.scss'

import HeaderContainer from './containers/navigation/HeaderContainer'

import UpsertBookingContainer  from './containers/bookings/UpsertBookingContainer'
import UserDashboardContainer from './containers/user_dashboard/UserDashboardContainer'
import LoginContainer from './containers/authentication/LoginContainer'
import SignUpContainer from './containers/authentication/SignUpContainer'
import ForgottenPasswordContainer from './containers/authentication/ForgottenPasswordContainer'
import AdminDashboardContainer from './containers/admin_dashboard/AdminDashboardContainer'
import OnboardingContainer from './containers/OnboardingContainer'
import FeedbackContainer from './containers/FeedbackContainer'

const PublicRoute = (props) => {
  const routeProps = { ...extractQueryParams(props), ...extractIds(props) }
  return <props.component routeProps={ routeProps }/>
}

const AuthenticatedRoute = (props) => {
  const currentUser = getCurrentUser(getState())
  if(currentUser){
    return PublicRoute(props)
  } else {
    return <Redirect from="" to={`/login?redirectPath=${props.uri}`} noThrow/>
  }
}

const OnboardingRoute = (props) => {
  const currentUser = getCurrentUser(getState())
  if(currentUser){
    const queryParams = extractQueryParams(props)
    const redirectPath = queryParams.type ? `/bookings?type=${queryParams.type}` : '/bookings?type=one_time'
    return <Redirect from="" to={ redirectPath } noThrow/>
  } else {
    return PublicRoute(props)
  }
}

const AdminRoute = (props) => {
  const currentUser = getCurrentUser(getState())
  if(currentUser && currentUser.get('admin')) {
    return PublicRoute(props)
  } else {
    return <Redirect from="" to={`/login?redirectPath=${props.uri}`} noThrow/>
  }
}

function App() {
  return (
    <div className="App">
      <HeaderContainer />
      <Container>
        <ModalContainer />
        <Router>
          <PublicRoute path="/login" component={ LoginContainer }/>
          <PublicRoute path="/signup" component={ SignUpContainer }/>
          <PublicRoute path="/" component={ LoginContainer }/>
          <PublicRoute path="/forgotten_password" component={ ForgottenPasswordContainer }/>
          <PublicRoute path="/feedback" component={ FeedbackContainer }/>
          <OnboardingRoute path="/onboarding_booking" component={ OnboardingContainer }/>

          <AuthenticatedRoute path="/bookings" component={ UpsertBookingContainer } />
          <AuthenticatedRoute path="users/:userId/bookings" component={ UpsertBookingContainer } />
          <AuthenticatedRoute path="/bookings/:bookingId" component={ UpsertBookingContainer } />
          <AuthenticatedRoute path="/users/:userId" component={ UserDashboardContainer } />

          <AuthenticatedRoute path="/admin_dashboard" component={ AdminDashboardContainer } />
        </Router>
      </Container>
    </div>
  );
}

export default App;
