import { fetchAction, simpleDispatchAction } from '../utils/actions'
import {
  UPDATE_ADMIN_BOOKINGS_WINDOW,
  FETCH_ADMIN_BOOKINGS_REQUESTED,
  FETCH_ADMIN_BOOKINGS_RECIEVED,
  FETCH_ADMIN_BOOKINGS_FAILED,
  UPDATE_ADMIN_REGION_FILTER,
  USER_SEARCH_REQUESTED,
  USER_SEARCH_RECIEVED,
  USER_SEARCH_FAILED
} from '../utils/constants'
import { apiDateFormat } from '../utils/date_helper'
import { shouldFetchAdminBookings } from '../reducers/bookings_reducer'

export function updateAdminBookingsWindow(windowStart, windowEnd){
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_ADMIN_BOOKINGS_WINDOW, windowStart, windowEnd } },
  })
}

export function updateRegionFilter(regionTitle){
  return simpleDispatchAction({
    action: () => { return { type: UPDATE_ADMIN_REGION_FILTER, regionTitle } },
  })
}

export function fetchAdminBookings({windowStart, windowEnd}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return shouldFetchAdminBookings(state, windowStart)
    },
    requestParams: {
      method: 'GET',
      url: `/admin/bookings?start_day=${apiDateFormat(windowStart)}&end_day=${apiDateFormat(windowEnd)}`
    },
    requestAction: () => { return { type: FETCH_ADMIN_BOOKINGS_REQUESTED } },
    receivedAction: (results) => { return { type: FETCH_ADMIN_BOOKINGS_RECIEVED, windowStart, results } },
    failureAction: (error) => { return { type: FETCH_ADMIN_BOOKINGS_FAILED, payload: { error } } }
  });
}

export function searchUsers(val, onSuccess) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'GET',
      url: `/users/search?val=${val}`
    },
    onSuccess,
    requestAction: () => { return { type: USER_SEARCH_REQUESTED } },
    receivedAction: (results) => { return { type: USER_SEARCH_RECIEVED, val, results } },
    failureAction: (error) => { return { type: USER_SEARCH_FAILED, payload: { error } } }
  });
}
