import { simpleDispatchAction } from '../utils/actions'
import {
  TOGGLE_MODAL,
} from '../utils/constants'

export function toggleModal(component, props) {
  return simpleDispatchAction({
    action: () => { return { type: TOGGLE_MODAL, props, component } },
  })
}
