import {
  LOGIN_REQUESTED,
  LOGIN_RECIEVED,
  LOGIN_FAILED,
  RECOVERY_CODE_CREATE_REQUESTED,
  RECOVERY_CODE_CREATE_RECIEVED,
  RECOVERY_CODE_CREATE_FAILED,
} from '../utils/constants'
import { fetchAction } from '../utils/actions'

export function loginUser({email, password, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: `/authenticate`,
      data: {
        email,
        password
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: LOGIN_REQUESTED } },
    receivedAction: (results) => { return { type: LOGIN_RECIEVED, results } },
    failureAction: (error) => { return { type: LOGIN_FAILED, payload: { error } } }
  });
}

export function sendRecoveryCode({email, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: '/password_recovery/send_code',
      data: {
        email
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: RECOVERY_CODE_CREATE_REQUESTED } },
    receivedAction: (results) => { return { type: RECOVERY_CODE_CREATE_RECIEVED } },
    failureAction: (error) => { return { type: RECOVERY_CODE_CREATE_FAILED } }
  });
}

export function validateRecoveryCode({email, code, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: '/password_recovery/validate_code',
      data: {
        code,
        email
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: RECOVERY_CODE_CREATE_REQUESTED } },
    receivedAction: (results) => { return { type: RECOVERY_CODE_CREATE_RECIEVED } },
    failureAction: (error) => { return { type: RECOVERY_CODE_CREATE_FAILED } }
  });
}

export function updatePassword({email, code, password, onSuccess, onFailure}) {
  return fetchAction({
    shouldCallAPI: (state) => {
      return true
    },
    requestParams: {
      method: 'POST',
      url: '/password_recovery/reset_password',
      data: {
        code,
        email,
        password
      }
    },
    onSuccess,
    onFailure,
    requestAction: () => { return { type: RECOVERY_CODE_CREATE_REQUESTED } },
    receivedAction: (results) => { return { type: RECOVERY_CODE_CREATE_RECIEVED } },
    failureAction: (error) => { return { type: RECOVERY_CODE_CREATE_FAILED } }
  });
}
