import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { required, email } from '../../utils/validations'
import BasicForm from '../forms/BasicForm'

export default class SendRecoveryCode extends Component {

  render() {
    return (
      <BasicForm
        fields={ this.fields() }
        onSubmit={ this.onSubmit }
      />
    )
  }

  fields = () => {
    return [{
      name: 'email',
      placeholder: 'Email Address',
      type: 'text',
      validations: [required, email]
    }]
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }
}

SendRecoveryCode.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
