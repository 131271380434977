import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { updateUser } from '../../actions/user_actions'
import { toggleModal } from '../../actions/modal_actions'
import { Alert } from 'reactstrap'
import '../../styles/containers/modal/user_updater_modal_container.scss'

class UserUpdaterModalContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: undefined,
      firstName: props.user.get('firstName'),
      lastName: props.user.get('lastName'),
      email: props.user.get('email'),
      phone: props.user.get('phone'),
      internalNote: props.user.get('internalNote'),
    }
  }

  render() {
    const {
      error,
      internalNote,
      firstName,
      lastName,
      email,
      phone
    } = this.state
    let errorMessage = error ? <Alert color="danger">{ error }</Alert> : null
    return (
      <div className='user-updater-modal-container'>
        <div className='user-updater-field-group'>
          <label>Internal Note</label>
          <textarea
            name="internalNote"
            onChange={ this.updateUser }
            placeholder="Internal Note For Admins Only"
          >
            { internalNote }
          </textarea>
        </div>
        <div className='user-updater-field-group'>
          <label>First Name</label>
          <input type='text' name="firstName" value={ firstName || '' } onChange={ this.updateUser }/>
        </div>
        <div className='user-updater-field-group'>
          <label>Last Name</label>
          <input type='text' name="lastName" value={ lastName || '' } onChange={ this.updateUser }/>
        </div>
        <div className='user-updater-field-group'>
          <label>Email</label>
          <input type='text' name="email" value={ email || '' } onChange={ this.updateUser }/>
        </div>
        <div className='user-updater-field-group'>
          <label>Phone</label>
          <input type='text' name="phone" value={ phone || '' } onChange={ this.updateUser }/>
        </div>
        <button className='btn btn-primary' onClick={ this.saveUser }>
          Update User
        </button>
        { errorMessage }
      </div>
    )
  }

  updateUser = (e) => {
    this.setState({[e.currentTarget.name]: e.currentTarget.value})
  }

  saveUser = () => {
    const {
      toggleModal,
      updateUser,
      user,
    } = this.props
    const {
      firstName,
      lastName,
      email,
      phone,
      internalNote
     } = this.state
    updateUser({
      userId: user.get('id'),
      params: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        internal_note: internalNote,
      },
      onSuccess: () => { toggleModal() },
      onFailure: (error) => {
        this.setState({ error: error.message || 'Uh Oh! Something went wrong. Please try again.' })
      },
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = {
  toggleModal,
  updateUser
}

UserUpdaterModalContainer.propTypes = {
  user: ImmutablePropTypes.map.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdaterModalContainer)
