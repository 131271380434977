import React, { Component } from 'react';
import Immutable from 'immutable'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { submitBooking, updateBooking } from '../../actions/bookings_actions'
import {
  getSelectedAddressId,
  getSelectedAddonIds,
  getSelectedCreditCardId,
  getSelectedDate,
  getSelectedTime,
  getDiscountCode
} from '../../reducers/upsert_booking_reducer'
import { getBooking } from '../../reducers/bookings_reducer'
import { getUserAddresses } from '../../reducers/address_reducer'
import { getAddons } from '../../reducers/addons_reducer'
import { getRegions } from '../../reducers/regions_reducer'
import { getDiscounts } from '../../reducers/discounts_reducer'
import { isFetchingBookings }  from '../../reducers/bookings_reducer'
import { calculatePrice } from '../../utils/price_helper'
import { getPriceForExistingBooking, getPriceForNewBooking } from '../../utils/price_helper_v2'
import { navigate } from '@reach/router'
import BookingCheckout from '../../components/bookings/BookingCheckout'
import { addonsFromIds } from '../../utils/addons_helper'

class BookingCheckoutContainer extends Component {
  constructor(props){
    super()

    this.state = {
      bookingError: undefined
    }
  }

  render() {
    const {
      bookingDate,
      bookingId,
      bookingTime,
      bookingType,
      cleaningPrices,
      discountCode,
      isSubmittingBooking,
      submitDisabled,
    } = this.props

    const { bookingError } = this.state

    return (
      <BookingCheckout
        bookingDate={ bookingDate }
        bookingId={ bookingId }
        bookingError={ bookingError }
        bookingTime={ bookingTime }
        bookingType={ bookingType }
        cleaningPrice={ cleaningPrices }
        discountCode={ discountCode }
        isSubmittingBooking={ isSubmittingBooking }
        submitBooking={ this.submitBooking }
        submitDisabled={ submitDisabled }
      />
    )
  }

  submitBooking = () => {
    const {
      bookingDate,
      bookingId,
      bookingTime,
      bookingType,
      cleaningPrices,
      discountCode,
      user,
      selectedAddress,
      selectedAddonIds,
      selectedCreditCard,
      submitBooking,
      updateBooking
    } = this.props
    this.setState({ bookingError: undefined })
    const onSuccess = () => navigate(`/users/${user.get('id')}`)
    const onFailure = () => this.setState({ bookingError: 'Uh Oh! Something went wrong and we were unable to complete your request. Please try again' })
    const attributes = {
      addonIds: selectedAddonIds.toJS(),
      addressId: selectedAddress,
      creditCardId: selectedCreditCard,
      date: bookingDate,
      discountCode,
      prices: cleaningPrices,
      time: bookingTime,
      type: bookingType,
      userId: user.get('id'),
      onSuccess,
      onFailure
    }

    if(bookingId){
      updateBooking({
        bookingId,
        ...attributes
      })
    } else {
      submitBooking(attributes)
    }
  }
}

const mapStateToProps = (state, props) => {
  const { bookingId, bookingType, user } = props
  const addons = getAddons(state)
  const selectedCreditCard = getSelectedCreditCardId(state)
  const selectedAddressId = getSelectedAddressId(state)
  const selectedAddonIds = getSelectedAddonIds(state)
  const discountCode = getDiscountCode(state)
  const discounts = getDiscounts(state)
  let cleaningPrices
  if(bookingId){
    const booking = getBooking(state, bookingId)
    cleaningPrices = getPriceForExistingBooking(booking, selectedAddonIds)
  } else {
    cleaningPrices = getPriceForNewBooking(
      bookingType,
      discountCode,
      selectedAddressId,
      selectedAddonIds,
      user.get('id')
    )
  }

  const submitDisabled = !selectedCreditCard
  return {
    addons,
    bookingDate: getSelectedDate(state),
    bookingTime: getSelectedTime(state),
    cleaningPrices,
    discountCode,
    isSubmittingBooking: isFetchingBookings(state),
    selectedAddress: selectedAddressId,
    selectedAddonIds,
    selectedCreditCard,
    submitDisabled,
    user
  }
}


const mapDispatchToProps = {
  submitBooking,
  updateBooking
}

BookingCheckoutContainer.propTypes = {
  bookingId: PropTypes.number,
  bookingType: PropTypes.string.isRequired,
  user: ImmutablePropTypes.map.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCheckoutContainer)
